import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { appendData, getData } from './actions';
import { IInitRimCalcState } from './rim.types';

const initialState: IInitRimCalcState = {
  list: [],
  limitedRows: [],
  total: [],
  loader: false,
  endFetch: false,
  page: 0,
  hiddenRows: new Set(),
  reset: false
};

export const rimSliceCalculation = createSlice({
  name: 'rimSliceCalculation',
  initialState,
  reducers: {
    reset(state) {
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
    },
    setHiddenRows(state, action: PayloadAction<Set<number | string>>) {
      state.hiddenRows = action.payload;
    },
    initList(
      state,
      action: PayloadAction<{
        list: IInitRimCalcState['list'];
        total: IInitRimCalcState['total'];
      }>
    ) {
      console.log('init', action.payload);
      state.list = action.payload.list;
      state.total = action.payload.total;
      state.endFetch = false;
    },
    togglePage(state, action: PayloadAction<number | undefined>) {
      state.page =
        action.payload !== undefined ? action.payload : state.page + 1;
    },
    appendRows(state, action: PayloadAction<IInitRimCalcState['list']>) {
      console.log('append', action.payload);
      state.list = state.list.concat(action.payload);
    },
    // addByID(
    //   state,
    //   action: PayloadAction<{ id: number; data: IInitRimCalcState['list'] }>
    // ) {
    //   const targetIndex = state.list.findIndex(
    //     (el) => el.id === action.payload.id
    //   );
    //   if (targetIndex >= 0) {
    //     state.list.splice(targetIndex + 1, 0, ...action.payload.data);
    //   }
    // },
    // removeByID(state, action: PayloadAction<number>) {
    //   const ids: number[] = [action.payload];
    //
    //   function flatFilter(
    //     children: IInitRimCalcState['list'],
    //     targetLevel: number
    //   ) {
    //     children.forEach((el) => {
    //       if (ids.includes(el.parentID!)) {
    //         if (el.children && el.level! > targetLevel) {
    //           ids.concat(el.children.map((item) => item.id));
    //           flatFilter(el.children!, targetLevel);
    //         }
    //       }
    //     });
    //   }
    //
    //   const targetIndex = state.list.findIndex(
    //     (el) => el.id === action.payload
    //   );
    //   if (targetIndex >= 0) {
    //     const target = state.list[targetIndex];
    //     flatFilter(target.children, target.level!);
    //     state.list = state.list.filter((el) => !ids.includes(el.parentID!));
    //   }
    // },
    // filterByID(state, action: PayloadAction<number>) {
    //   const ids = new Map<number, number>();
    //   ids.set(action.payload, action.payload);
    //   const newList: IInitRimCalcState['list'] = [];
    //   for (let i = 0; i < state.list.length; i++) {
    //     const el = state.list[i];
    //     if (!ids.has(el.parentID!) || el.parentID === null) {
    //       newList.push(el);
    //     } else {
    //       ids.set(el.id, el.id);
    //     }
    //     // if (state.list[i].type === clickTarget.type) break;
    //   }
    //   console.log(newList);
    //   state.filterList = newList;
    //   // function flatFilter(
    //   //   children: IInitBimCalcState['list'],
    //   //   targetLevel: number
    //   // ) {
    //   //   children.forEach((el) => {
    //   //     if (ids.includes(el.parentID!)) {
    //   //       if (el.children && el.level! > targetLevel) {
    //   //         ids.concat(el.children.map((item) => item.id));
    //   //         flatFilter(el.children!, targetLevel);
    //   //       }
    //   //     }
    //   //   });
    //   // }
    //   // const targetIndex = state.list.findIndex(
    //   //   (el) => el.id === action.payload
    //   // );
    //   // if (targetIndex >= 0) {
    //   //   const target = state.list[targetIndex];
    //   //   flatFilter(target.children, target.level!);
    //   //   state.list = state.list.filter((el) => !ids.includes(el.parentID!));
    //   // }
    // },
    toggleLoader(state, action: PayloadAction<boolean | undefined>) {
      if (action.payload !== undefined) {
        state.loader = action.payload;
      } else {
        state.loader = !state.loader;
      }
    },
    clear(state) {
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
    },
    toggleEndFetch(state, action: PayloadAction<boolean | undefined>) {
      state.endFetch =
        action.payload !== undefined ? action.payload : !state.endFetch;
    }
  },
  extraReducers(builder) {
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      // let d: ComplicatedRimRow[] = [
      //   {
      //     id: uuid4(),
      //     type: 'limit-header',
      //     title: 'лимитированные',
      //     hasChildren: false,
      //     including: {
      //       building: null,
      //       equipment: null,
      //       mounting: null,
      //       other: null
      //     },
      //     lvl: 1
      //   }
      // ];
      // d = d.concat(action.payload.limitedCostsAsRows);
      // let list = action.payload.data;
      // if (action.payload.limitedCostsAsRows.length) {
      //   list = list.concat(d);
      // }
      // console.log('list redux', list);
      state.list = action.payload.data;
      state.total = [action.payload.total];
      state.limitedRows = action.payload.limitedCostsAsRows;
    });
    builder.addCase(appendData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      // if (action.payload.limitedCostsAsRows.length) {
      // let d: ComplicatedRimRow[] = [
      //   {
      //     id: uuid4(),
      //     type: 'limit-header',
      //     title: 'лимитированные',
      //     hasChildren: false,
      //     including: {
      //       building: null,
      //       equipment: null,
      //       mounting: null,
      //       other: null
      //     },
      //     lvl: 1
      //   }
      // ];
      // d = d.concat(action.payload.limitedCostsAsRows);
      // d.concat(
      //   limitedRows.map((el) => ({
      //     ...el,
      //     id: uuid4(),
      //     type: 'limit'
      //   }))
      // );
      // // }
      // if (action.payload.limitedCostsAsRows.length) {
      //   state.list.splice(
      //     state.list.length - 1 - action.payload.limitedCostsAsRows.length + 1,
      //     action.payload.limitedCostsAsRows.length + 1
      //   );
      // }
      // // action.payload.data.splice(0, 1000);
      // let list = ;
      // if (action.payload.limitedCostsAsRows.length) {
      //   list = list.concat(d);
      // }
      state.list = state.list.concat(action.payload.data);
      // state.total = [action.payload.total];
      // state.limitedRows = action.payload.limitedCostsAsRows;
    });
  }
});
export const getRimCalcState = (state: RootState) =>
  state.calculations.rim.calc;
export const {
  reset,
  togglePage,
  clear,
  initList,
  appendRows,
  toggleLoader,
  toggleEndFetch,
  setHiddenRows
} = rimSliceCalculation.actions;
export default rimSliceCalculation.reducer;
