/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  IRowNode,
  NewValueParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProjectLabel } from 'components/ProjectLabel';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useProjectId } from 'hooks/useProjectId';
import { enqueueSnackbar } from 'notistack';
import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { useEditQuantityMutation } from '../../../../api/calculations';
import { CalculationLabel } from '../../../../components/CalculationLabel';
import Progress from '../../../../components/Progress';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import {
  ActList,
  ExecutionCalculationData,
  GetExecutionCalculationData
} from '../../../../types';
import { ActListContext, BimStepper } from '../CalculationСomplicated';
import { PageStyled, WrapperAgGrid } from './Accomplishment.styles';
import { IPricesSwitch } from './Accomplishment.types';
import ActDialog from './components/ActDialog';
import CaptionTable from './components/CaptionTable';
import ExportDialog from './components/ExportDialog';
import { getRowClass, useTable } from './helper';
import { useStepperContext } from '../../../../hooks/useStepper';
import {
  ENUMLocalStorage,
  getLocalStorage,
  useLocalStorage
} from '../../../../hooks/use-local-storage';

const defaultColDef = { resizable: true };
const priceSwitches: IPricesSwitch[] = [
  { name: 'Базовые', value: 'base' },
  { name: 'Текущие', value: 'curr' }
];

interface TableContext {
  collapse?: (id: number) => void;
  hiddenRowsIds?: number[];
  filteredData?: ExecutionCalculationData[];
  prices?: 'curr' | 'base';
  current?: ActList | null;
  updateData?: UpdateParams;
  total?: ExecutionCalculationData | null;
  calcID?: string;
  emptyCurr?: boolean;
}

type UpdateParams = (
  params: NewValueParams<ExecutionCalculationData, unknown>,
  act: ActList | null,
  calcID?: number | string
) => Promise<void>;

export const AgContext = createContext<TableContext>({});

const Accomplishment = () => {
  const projectID = useProjectId();

  const {
    calculation,
    current,
    table: d,
    isFetching,
    getTable,
    refetchActs,
    data: actResponseData
  } = useContext(ActListContext);

  const { depth, setMaxDepth, maxDepth } = useStepperContext<BimStepper>();
  const firstLoad = useRef(true);
  const [data, setData] = useState<GetExecutionCalculationData | undefined>(
    undefined
  );
  const getNestedChildrenIds = useCallback(
    (id: number, defaultData: ExecutionCalculationData[]): number[] => {
      // console.log(data?.tree);
      const children = defaultData.filter((e) => e.parent_id === id);
      console.log(children, id);
      return Array.from(
        new Set(
          children.reduce<number[]>(
            (acc, child) =>
              [
                ...acc,
                child.id,
                id,
                ...getNestedChildrenIds(child.id, defaultData)
              ].filter((e) => e !== undefined),
            []
          )
        )
      );
    },
    [data?.tree]
  );
  const [prices, setPrices] = useState<'curr' | 'base'>('curr');
  const [updateLoader, setUpdateLoader] = useState(false);
  const { calcID } = useParams();
  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);

  const { setValue } = useLocalStorage(
    ENUMLocalStorage.levelsBaseMethodExecute,
    []
  );

  const [gridRef, setGridRef] = useState<GridReadyEvent<
    ExecutionCalculationData,
    unknown
  > | null>(null);
  const Ref = useRef<AgGridReact<ExecutionCalculationData> | null>(null);
  const emptyCurr = useMemo(() => {
    let flag = true;
    /**
     * total.curr не пустой - есть текущие цены
     * parts[любая партия].curr - есть текущие цены
     */
    if (data?.total && data.total?.[0]?.parts) {
      flag = data.total[0].parts.some((part) => {
        let foundTotalKey: keyof typeof part.curr;
        for (foundTotalKey in part.curr) {
          if (
            !Array.isArray(part.curr[foundTotalKey]) &&
            foundTotalKey !== 'hasIndex' &&
            foundTotalKey !== 'hasError'
          ) {
            if (part.curr[foundTotalKey] !== null) {
              return true;
            }
          }
        }
        return false;
      });
      if (!flag) {
        setPrices('base');
      }
    }
    return !flag;
  }, [data?.total, isFetching]);

  const columnsRef: MutableRefObject<
    | (
        | ColDef<ExecutionCalculationData, any>
        | ColGroupDef<ExecutionCalculationData>
      )[]
    | undefined
  > = useTable(data, Ref, emptyCurr, isFetching);

  const [createModal, setCreateModal] = useState(false);
  const [exportModal, setExportModal] = useState('');
  // const { data, isFetching } = useGetExecutionCalculationQuery(
  //   { calcID: Number(calcID) },
  //   { skip: calcID === undefined },
  // );

  const [filteredData, setFilteredData] = useState<
    ExecutionCalculationData[] | undefined
  >(undefined);

  const changePrices = (price: IPricesSwitch) => {
    setPrices(price.value as typeof prices);
  };

  const [update, updateResponse] = useEditQuantityMutation();

  const [, setEditingCell] = useState(false);

  const onCellEditingStarted = useCallback(() => {
    setEditingCell(true);
  }, []);

  const updateData: UpdateParams = async (params, act, calcID) => {
    setUpdateLoader(true);
    params.api.showLoadingOverlay();
    gridRef?.api.showLoadingOverlay();

    try {
      if (act?.id && calcID) {
        const editedData = {
          actID: act.id,
          calcID: Number(calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue)
          }
        };
        await update(editedData).then(() => refetchActs?.());
        setEditingCell(false);
      }
    } catch (error) {
      setEditingCell(false);
      // Обработка ошибки
      console.error('Error:', error);
    }
    return;
  };
  useEffect(() => {
    if (isFetching && d === undefined) columnsRef.current = undefined;
    if (!isFetching) {
      setData(d);
    }
  }, [d, isFetching]);

  const collapse = useCallback(
    (id: number) => {
      // if (setHiddenRowsIds) {
      console.log(data);
      const arrIndexes = getNestedChildrenIds(id, data?.tree || []);
      console.log(arrIndexes, id);
      setHiddenRowsIds((d) => {
        const copy = [...d];
        if (copy.includes(id)) {
          const idsToRemove = getNestedChildrenIds(id, data?.tree || []);
          return Array.from(
            new Set(copy.filter((hrId) => ![id, ...idsToRemove].includes(hrId)))
          );
        } else {
          return Array.from(new Set([...copy, id]));
        }
        // arrIndexes.forEach((elId) => {
        //   const addIndex = copy.findIndex((hrId) => hrId === elId);
        // const addIndex = copy.findIndex((hrId) => hrId === id);
        // if (addIndex >= 0) {
        //   copy.splice(addIndex, 1);
        // } else {
        //   copy.push(id);
        // }
        // });
        // return copy;
      });
      // }
    },
    [data]
  );
  console.log(hiddenRowsIds);
  useLayoutEffect(() => {
    gridRef?.api.showLoadingOverlay();
    if (data?.tree) {
      (async () => {
        let max = 1;

        data.tree.forEach((el) => {
          if (el.type !== 'sum') {
            const lvl = el.type === 'file' ? 1 : el.type === 'level' ? 2 : 3;
            max = max < lvl ? lvl : max;
          }
        });
        setMaxDepth('executed', max);
      })();
      if (firstLoad.current) {
        const storage =
          getLocalStorage(ENUMLocalStorage.levelsBaseMethodExecute) ?? [];
        const findLevels = storage.find((level) => String(level.id) === calcID);
        if (findLevels) {
          setHiddenRowsIds(findLevels.levels);
        }
        firstLoad.current = false;
      } else {
        setHiddenRowsIds((d) => {
          const copy = [...d];
          if (!copy.length) {
            data.tree
              .filter((item) => item.type === 'file' || item.type === 'level')
              .forEach((file) => copy.push(file.id));
          }

          return copy;
        });
      }
    }
  }, [data?.tree]);

  useMutationHandlers(
    updateResponse,
    () => {
      enqueueSnackbar('Данные успешно обновлены', {
        variant: 'success'
      });
    },
    (error) => {
      console.log('<<< ERROR >>>', error);
      enqueueSnackbar('Есть проблемы', {
        variant: 'error'
      });
    }
  );

  useEffect(() => {
    if (calcID) {
      getTable?.(Number(calcID));
    }
  }, [calcID]);

  useEffect(() => {
    if ((isFetching && gridRef) || updateLoader) {
      gridRef?.api.showLoadingOverlay();
      // setFilteredData([]);
      // oneLoadData.current = false;
    }
  }, [isFetching, gridRef, updateLoader]);

  const [contextTable, setContextTable] = useState<TableContext>({
    calcID,
    emptyCurr,
    collapse,
    current,
    updateData,
    filteredData,
    prices,
    total: data?.total[0],
    hiddenRowsIds
  });

  useEffect(() => {
    setContextTable((prevState) => ({
      ...prevState,
      total: data?.total?.[0],
      hiddenRowsIds,
      current,
      calcID,
      emptyCurr,
      filteredData,
      prices,
      collapse
    }));
  }, [
    data,
    hiddenRowsIds,
    current,
    calcID,
    emptyCurr,
    prices,
    isFetching,
    collapse
  ]);

  useEffect(() => {
    Ref.current?.api?.refreshCells({ force: true });
    Ref.current?.api?.refreshHeader();
  }, [contextTable, Ref.current, prices]);

  useEffect(() => {
    if (filteredData?.length && gridRef) {
      // gridRef?.api?.applyTransaction({ update: filteredData });
      // gridRef?.api?.refreshCells({ force: true });
    }
  }, [filteredData, gridRef]);

  useEffect(() => {
    if (isFetching) {
      if (!updateLoader) {
        setFilteredData(undefined);
        setData(undefined);
        columnsRef.current = undefined;
      }
    }
  }, [isFetching, updateLoader]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ? (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        ) : (
          'Предпросмотр'
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );
  const doesExternalFilterPass = (
    params: IRowNode<ExecutionCalculationData>
  ) => {
    try {
      Ref.current?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parent_id
        ? hiddenRowsIds.includes(params.data.parent_id)
        : true;
    } catch (e) {
      return false;
    }
  };
  useEffect(() => {
    if (data?.tree && maxDepth.executed && !firstLoad.current) {
      (async () => {
        setHiddenRowsIds([]);
        console.log(data.tree);
        const tempArr: number[] = [];
        data.tree.forEach((item) => {
          if (item.type !== 'sum') {
            const lvl =
              item.type === 'file' ? 1 : item.type === 'level' ? 2 : 3;
            if (lvl < depth.executed) {
              tempArr.push(item.id);
              // collapse(item.id);
            }
          }
        });
        setHiddenRowsIds(tempArr);
        // Ref.current?.api?.setIsExternalFilterPresent(() => true);
        // Ref.current?.api?.onFilterChanged();
      })();
    }
  }, [depth.executed]);
  useEffect(() => {
    if (firstLoad.current) {
      const storage =
        getLocalStorage(ENUMLocalStorage.levelsBaseMethodExecute) ?? [];
      const findLevels = storage.find((level) => String(level.id) === calcID);
      if (findLevels) {
        setHiddenRowsIds(findLevels.levels);
      }
    }
  }, []);
  useEffect(() => {
    if (!firstLoad.current) {
      setValue((prevState) => {
        const isFind = prevState?.find((level) => String(level.id) === calcID);
        if (isFind) {
          return prevState?.map((level) => {
            if (String(level.id) === calcID) {
              return {
                id: Number(calcID),
                levels: hiddenRowsIds
              };
            }
            return level;
          });
        } else {
          return [
            ...(prevState ?? []),
            {
              id: Number(calcID),
              levels: hiddenRowsIds
            }
          ];
        }
      });
    }
  }, [hiddenRowsIds]);
  useEffect(() => {
    Ref.current?.api?.setIsExternalFilterPresent(() => true);
    Ref.current?.api?.onFilterChanged();
  }, [doesExternalFilterPass, Ref.current, location.pathname, hiddenRowsIds]);
  return (
    <PageStyled>
      <AgContext.Provider value={contextTable}>
        <CaptionTable
          disableCurr={emptyCurr}
          act={() => setCreateModal(true)}
          exportKS={(str: string) => setExportModal(str)}
          prices={prices}
          changePrices={changePrices}
          priceSwitches={priceSwitches}
        />
        <WrapperAgGrid className="ag-theme-material reference-prices">
          <AgGridReact
            ref={Ref}
            onGridReady={(e) => setGridRef(e)}
            context={contextTable}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            columnDefs={columnsRef.current}
            defaultColDef={defaultColDef}
            groupHeaderHeight={25}
            singleClickEdit
            gridOptions={{
              onCellEditingStarted,
              // suppressDragLeaveHidesColumns: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null
            }}
            pinnedTopRowData={data?.total}
            rowData={data?.tree}
            suppressCellFocus={true}
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
            }}
            // onGridSizeChanged={(event: GridSizeChangedEvent) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            getRowClass={getRowClass}
            getRowHeight={(params) => {
              if (params.node.rowPinned === 'top') {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: '0 !important'
            }}
            rowHeight={55}
            headerHeight={36}
            doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={Progress}></AgGridReact>
        </WrapperAgGrid>
        <ActDialog
          data={actResponseData}
          update={(f) => {
            columnsRef.current = undefined;
            gridRef?.api.showLoadingOverlay();
            setUpdateLoader(f);
            firstLoad.current = true;
            // setFilteredData(null);
          }}
          close={() => setCreateModal(false)}
          open={createModal}
        />
        <ExportDialog close={() => setExportModal('')} open={exportModal} />
      </AgContext.Provider>
    </PageStyled>
  );
};

export default Accomplishment;
