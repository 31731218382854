import {
  Box,
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useDeleteActMutation,
  useGetRimExecutionCoefficientsQuery,
  useSyncCoefficientsActMutation,
  useUpdateActMutation,
  useUpdateRimExecutionCoefficientsMutation
} from 'api/params';
import { isAfter, isBefore } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import useSearchParam from 'hooks/useSearchParams';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import {
  ActList,
  ActResponse,
  CalcData,
  UpdateRimExecutionCoefficientsDataResponse
} from 'types';
import { LabelsGroup } from '../../../../../../api/params/params.types';
import {
  formatDateToString,
  getFormatDate,
  isSameDate,
  toLocalString
} from '../../../../../../utils/formatDate';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { StyledSelect } from '../../../../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style';
import { SelectField } from '../../../../../Calculations/components/CalculationBasic/components/ParametersDialog/ParametersDialog.styles';
import { IndexMethodSearchParamsTab } from '../../../index-method.types';
import { Content } from './content';
import { modifyRequest, modifyResponse } from './content/components';
import {
  formikSetup,
  setupDialogStyle
} from './parameters-dialog.execution.constants';
import { ParametersDialogTabs } from './parameters-dialog.execution.tabs';
import {
  DialogForm,
  TParametersDialogProps,
  TParametersTabs
} from './parameters-dialog.execution.types';
import {
  BtnWrapper,
  Header,
  Wrapper
} from './parameters-dilog.execution.style';

const formatDate = (act: ActList) => {
  if (!act.startDate || !act.endDate) return '';
  return (
    getFormatDate({ date: act.startDate }) +
    ' — ' +
    getFormatDate({ date: act.endDate })
  );
};
export const ParametersDialogExecution: React.FC<
  TParametersDialogProps & {
    actList?: ActResponse;
    currentAct: ActList | null;
    changeCurrentAct: (act: ActList | null) => void;
    refetchActs: () => void;
    calculation?: CalcData | null;
    updateFragment: () => void;
  }
> = ({
  onClose,
  updateFragment,
  open,
  changeCurrentAct,
  refetchActs,
  currentAct,
  actList,
  calculation
}) => {
  const firstLoad = React.useRef(true);
  const [tab, setTab] = React.useState<TParametersTabs>('acts');

  const [syncState, setSyncState] = React.useState(false);

  const [calcInfo] = useSearchParam<IndexMethodSearchParamsTab>({
    key: 'tab'
  });

  const isExecuted = useMemo(() => {
    return calcInfo === 'execution';
  }, [calcInfo]);

  const calcID = useCalcId();

  const {
    data: responseData,
    isFetching,
    refetch
  } = useGetRimExecutionCoefficientsQuery(
    { calcID, actID: currentAct?.id! },
    { skip: !isExecuted || !currentAct?.id || !open }
  );
  const onRefetch = (form: typeof formik) => {
    refetch().then((response) => {
      if ('data' in response) {
        console.log('log: ', response);
        setSyncState(!!response.data?.synchronized);
        form.resetForm({
          values: {
            ...form.values,
            rows: modifyResponse(
              response.data!.limitedCostData
            ) as DialogForm['rows'],
            removed: [],
            removedActs: [],
            estimates: response.data!.limitedCostData.estimates || [],
            synchronized: !!response.data?.synchronized,
            currentAct
          }
        });
      }
    });
  };

  const [updateAct] = useUpdateActMutation();
  const [deleteAct] = useDeleteActMutation();
  const [syncAct] = useSyncCoefficientsActMutation();

  const [updateFunc, requestInfo] = useUpdateRimExecutionCoefficientsMutation();

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    }
  });
  // for (const key in exampleDataFromServer) {
  const data: DialogForm = React.useMemo(() => {
    console.log('update data');
    const acts =
      actList?.actList
        // ?.filter((act) => !removedActsIdx.includes(act.id))
        ?.reduce((acc, prev) => {
          // const title = prev.estimateName || 'Общие';
          const label =
            'Акты за ' +
            formatDateToString(new Date(prev.onDate || ''), 'yyyy');

          const idxGroup = acc.findIndex((_) => _.label === label);
          //
          // prev.forEach((_) => {

          const update = {
            ...prev,
            onDate: new Date(prev.onDate || ''),
            startDate: new Date(prev.startDate || ''),
            endDate: new Date(prev.endDate || '')
          };
          if (idxGroup >= 0) {
            acc[idxGroup].fields = [...acc[idxGroup].fields, update];
          } else {
            acc.push({ label, fields: [update] });
          }
          // });
          return acc;
        }, [] as LabelsGroup[]) || [];
    setSyncState(!!responseData?.synchronized);
    return {
      rows: [],
      estimates: [],
      removed: [],
      removedActs: [],
      currentAct,
      acts,
      calculation,
      synchronized: !!responseData?.synchronized
    };
  }, [isExecuted]);

  const formik = useFormik<DialogForm>({
    ...formikSetup,
    initialValues: data,
    onSubmit: (values: DialogForm) => {
      const errors: { [key: string]: { er: ReturnType<typeof checkDates> } } =
        {};
      console.log('values>>>', values);
      values.acts?.forEach((labels) => {
        labels.fields.forEach((act, idx) => {
          const er = checkDates(act);

          const key = `За ${new Date(act.onDate || '')?.getFullYear()} год, строка ${idx + 1}`;
          if (er?.end || er?.on || er?.split || er?.start) {
            if (errors[key]) {
              errors[key] = { ...errors[key], er };
            } else {
              errors[key] = { er };
            }
          }
        });
      });
      const keysErrors = Object.keys(errors);
      if (keysErrors.length) {
        enqueueSnackbar(
          <Box display={'flex'} flexDirection={'column'} gap={0.05}>
            Ошибка в Актах по периодам:
            {keysErrors.map((label) => {
              const err = errors[label].er;
              return (
                <Box key={label} display={'flex'} flexDirection={'column'}>
                  <p>{label}</p>
                  <Box display={'flex'} gap={0.2} flexDirection={'column'}>
                    {err?.start}
                    {err?.end}
                    {err?.on}
                    {err?.split}
                  </Box>
                </Box>
              );
            })}
          </Box>,
          {
            variant: 'error',
            autoHideDuration: 3000
          }
        );

        return;
      }
      if (formik.getFieldMeta('acts').touched) {
        updateAct({
          calcID,
          values: (formik.values.acts || []).flatMap((label) => {
            // _.groups.flatMap(() => {
            return label.fields.flatMap((act) => {
              const { id, startDate, endDate } = act;
              if (currentAct?.id === id) {
                changeCurrentAct?.({
                  ...currentAct,
                  id: currentAct.id,
                  endDate: toLocalString(endDate as Date),
                  startDate: toLocalString(startDate as Date),
                  onDate: currentAct.onDate
                });
              }
              return {
                id,
                endDate: toLocalString(endDate as Date),
                startDate: toLocalString(startDate as Date)
              };
            });
            // }),
          })
        });
      }
      if (formik.getFieldMeta('removedActs').touched) {
        if (values.removedActs.length === actList?.actList.length)
          changeCurrentAct(null);
        console.log();
        values.removedActs.forEach((act) => {
          deleteAct({
            calcID,
            actID: act.id
          }).then(updateFragment);
        });
      }

      if (!values.currentAct) return;
      if (syncState) {
        syncAct({
          calcID,
          actID: values.currentAct?.id!,
          projectType: 'grandSmeta'
        }).then(() => {
          updateFragment();
          firstLoad.current = true;
          onRefetch(formik);
        });
      } else {
        const requestData: UpdateRimExecutionCoefficientsDataResponse = {
          body: {
            limitedCosts: [
              ...modifyRequest(values.rows),
              ...modifyRequest(values.removed)
            ]
          },
          calcID,
          actID: currentAct?.id!
        };
        updateFunc(requestData).then((response) => {
          if ('data' in response) {
            updateFragment();
            setSyncState(!!response.data?.synchronized);
            formik.resetForm({
              values: {
                ...formik.values,
                rows: modifyResponse(
                  response.data!.limitedCostData
                ) as DialogForm['rows'],
                removed: [] as DialogForm['removed'],
                removedActs: [] as DialogForm['removedActs'],
                estimates: response.data!.limitedCostData.estimates || [],
                synchronized: !!response.data?.synchronized,
                currentAct
              }
            });
          }
        });
      }
      // updateFunc(requestData);
      // formik.resetForm({ values: { ...formik.values, removedActs: [] } });
    }
  });

  const disabledDates: { start: Date; end: Date; id: number }[] =
    useMemo(() => {
      if (!formik.values.acts) return [];
      return formik.values.acts.reduce(
        (acc: { start: Date; end: Date; id: number }[], item) => {
          acc.push(
            ...item.fields.flatMap((dates) => ({
              start: new Date(dates.startDate || ''),
              end: new Date(dates.endDate || ''),
              id: dates.id
            }))
          );
          // acc.push(
          //   ...item.groups.flatMap((_) =>
          //     _.fields.flatMap((dates) => ({
          //       start: new Date(dates.startDate),
          //       end: new Date(dates.endDate),
          //       id: dates.id,
          //     })),
          //   ),
          // );
          return acc;
        },
        []
      );
    }, [formik.values.acts]);

  const checkDates = useCallback(
    (data: ActList) => {
      /* Получаем актуальный список дат, все кроме входящей даты */
      const filteredDisabledDates = disabledDates.filter(
        (d) => d.id !== data.id
      );

      /**
       * Объект для формирования ошибок
       * start - попадает сюда, если есть ошибка в поле "Дата начала"
       * end - попадает сюда, если есть ошибка в поле "Дата окончания"
       * on - попадает сюда, если есть ошибка в поле "Сформирован на дату"
       * split - попадает сюда, если даты перепутаны местами, например, дата начала идет после даты конца
       */
      const errorText: {
        start: null | React.ReactElement;
        end: null | React.ReactElement;
        on: null | React.ReactElement;
        split: null | React.ReactElement;
      } = { start: null, end: null, on: null, split: null };

      if (
        (data.startDate && isNaN((data.startDate as Date).getTime())) ||
        data.startDate === null
      ) {
        errorText.start = <span>Дата начала некорректна</span>;
      }
      if (
        (data.endDate && isNaN((data.endDate as Date).getTime())) ||
        data.endDate === null
      ) {
        errorText.end = <span>Дата окончания некорректна</span>;
      }
      if (
        (data.onDate && isNaN((data.onDate as Date).getTime())) ||
        data.onDate === null
      ) {
        errorText.on = <span>Дата формирования некорректна</span>;
      }
      if (
        data.startDate &&
        !isNaN((data.startDate as Date).getTime()) &&
        data.endDate &&
        !isNaN((data.endDate as Date).getTime()) &&
        data.onDate &&
        !isNaN((data.onDate as Date).getTime())
      ) {
        for (const { start, end } of filteredDisabledDates) {
          if (
            (!errorText.start &&
              (isSameDate(data.startDate as Date, start) ||
                isAfter(data.startDate as Date, start)) &&
              (isSameDate(data.startDate as Date, end) ||
                isBefore(data.startDate as Date, end))) ||
            (isBefore(data.startDate as Date, start) &&
              isAfter(data.endDate as Date, end))
          ) {
            errorText.start = (
              <span>Дата начала пересекает существующие акты</span>
            );
          }
          if (
            !errorText.end &&
            (isSameDate(data.endDate as Date, start) ||
              isAfter(data.endDate as Date, start)) &&
            (isSameDate(data.endDate as Date, end) ||
              isBefore(data.endDate as Date, end))
          ) {
            errorText.end = (
              <span>Дата окончания пересекает существующие акты</span>
            );
          }
          if (
            !errorText.on &&
            isSameDate(data.startDate as Date, data.endDate as Date)
          ) {
            errorText.on = (
              <span>Даты не могут быть установлены одним днем</span>
            );
          }
          if (
            !errorText.split &&
            (isBefore(data.endDate as Date, data.startDate as Date) ||
              isAfter(data.startDate as Date, data.endDate as Date))
          ) {
            errorText.split = (
              <span>Дата начала не может идти после даты окончания</span>
            );
          }
        }
      }

      if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
        return errorText;
      } else {
        return {} as typeof errorText;
      }
    },
    [disabledDates]
  );

  const onChange = (
    _: React.SyntheticEvent<Element, Event>,
    t: TParametersTabs
  ) => {
    setTab(t);
  };
  const handleClose = React.useCallback(() => {
    const errors = Object.values(formik.errors)?.length;
    if (errors || formik.dirty) {
      openConfirm(() => {
        onClose();
      });
      return;
    }
    onClose();
  }, [formik.errors, onClose, openConfirm, formik.dirty]);

  React.useEffect(() => {
    if (!open) {
      localStorage.removeItem('removedActs');
      setSyncState(false);
      formik.resetForm({
        values: {
          rows: [],
          estimates: [],
          removed: [],
          removedActs: [],
          currentAct: null,
          acts: [],
          calculation: null,
          synchronized: false
        }
      });
      firstLoad.current = true;
    }
  }, [open]);

  useMutationHandlers(requestInfo, () => {
    enqueueSnackbar('Параметры расчета сметы успешно изменены', {
      variant: 'success'
    });
  });

  const { openConfirm: openChangeConfirm, ConfirmDialog: ChangeConfirmDialog } =
    useConfirmDialog({
      title: 'Подтвердите смену акта',
      body: 'После смены текущего акта, все изменения будут безвозвратно утеряны',
      handleConfirm: (confirm, fn) => {
        if (confirm) {
          fn?.();
        }
      }
    });

  const changeModalHandler = useCallback(
    (act: ActList) => {
      if (formik.dirty) {
        openChangeConfirm(() => changeCurrentAct?.(act));
        return;
      }
      changeCurrentAct?.(act);
    },
    [formik.dirty, changeCurrentAct]
  );

  const renderAct = useMemo(() => {
    if (!currentAct) return 'Актов нет';
    return formatDate(currentAct);
  }, [currentAct]);
  const renderActs = useMemo(() => {
    const removed = formik.values.removedActs.map((_: any) => _?.id);
    if (!actList?.actList) return [];
    if (!removed.length)
      return actList?.actList.map((item: any) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (currentAct?.id !== item.id) {
                changeModalHandler(item);
                firstLoad.current = true;
              }
            }}
            selected={
              formatDate(item) === renderAct && item.id === currentAct?.id
            }
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });

    return actList?.actList
      .filter((_: any) => !removed.includes(_.id))
      .map((item: any) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (currentAct?.id !== item.id) {
                changeModalHandler(item);
              }
            }}
            selected={
              formatDate(item) === renderAct && item.id === currentAct?.id
            }
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });
  }, [formik.values]);

  React.useEffect(() => {
    if (responseData && firstLoad.current && !isFetching) {
      console.log('log: ', responseData);
      setSyncState(!!responseData?.synchronized);
      formik.resetForm({
        values: {
          ...formik.values,
          rows: modifyResponse(
            responseData.limitedCostData
          ) as DialogForm['rows'],
          estimates: responseData.limitedCostData.estimates || [],
          synchronized: !!responseData?.synchronized,
          currentAct
        }
      });
      firstLoad.current = false;
    }
  }, [responseData, currentAct, formik, firstLoad.current, isFetching]);

  React.useEffect(() => {
    if (isFetching) {
      console.log('update');
      firstLoad.current = true;
    }
  }, [isFetching]);

  React.useEffect(() => {
    if (open) {
      const acts =
        actList?.actList
          // ?.filter((act) => !removedActsIdx.includes(act.id))
          ?.reduce((acc, prev) => {
            // const title = prev.estimateName || 'Общие';
            const label =
              'Акты за ' +
              formatDateToString(new Date(prev.onDate || ''), 'yyyy');

            const idxGroup = acc.findIndex((_) => _.label === label);
            //
            // prev.forEach((_) => {

            const update = {
              ...prev,
              onDate: new Date(prev.onDate || ''),
              startDate: new Date(prev.startDate || ''),
              endDate: new Date(prev.endDate || '')
            };
            if (idxGroup >= 0) {
              acc[idxGroup].fields = [...acc[idxGroup].fields, update];
            } else {
              acc.push({ label, fields: [update] });
            }
            // });
            return acc;
          }, [] as LabelsGroup[]) || [];
      console.log('log: ', acts);
      formik.resetForm({
        values: {
          ...formik.values,
          removed: [],
          removedActs: [],
          calculation,
          ...(firstLoad.current ? { acts } : {})
        }
      });
    }
  }, [actList?.actList, open]);

  const disabledSubmit = React.useMemo(() => {
    const initSync = formik.initialValues.synchronized;
    const checkSync = !initSync && syncState;
    if (formik.values.removedActs.length) return true;
    return (
      (!!formik.errors?.rows &&
        !!Object.values(formik.errors.rows).filter((el) => !!el).length) ||
      (formik.dirty && formik.isValid) ||
      checkSync
    );
  }, [formik, syncState, requestInfo.isLoading]);

  React.useEffect(() => {
    if (!firstLoad.current) {
      console.group('effect formik sync');
      const syncDefault = formik.initialValues.synchronized;
      console.log('initial effect', formik.initialValues.synchronized);
      console.log('formik', formik);
      // if (
      //   !formik.getFieldProps('acts').value.flatMap((el: any) => el.fields)
      //     .length
      // )
      //   setSyncState(false);
      const dirtyArr = Object.keys(formik.values).filter((field) => {
        return (
          formik.values[field as keyof typeof formik.values] !==
          formik.initialValues[field as keyof typeof formik.initialValues]
        );
      });
      console.log('dirtyArr', dirtyArr);
      const isRowsChanged =
        dirtyArr.includes('rows') || dirtyArr.includes('removed');
      console.log('isRowsChanged', isRowsChanged);
      // console.log(
      //   Object.keys(formik.values).filter((field) => {
      //     return (
      //       formik.values[field as keyof typeof formik.values] !==
      //       formik.initialValues[field as keyof typeof formik.initialValues]
      //     );
      //   })
      // );
      console.log('syncDefault && isRowsChanged', syncDefault && isRowsChanged);
      if (syncDefault && isRowsChanged) {
        // console.log(formik.touched, formik.values, formik.initialValues);
        // console.log();
        // console.log(formik.touched);
        console.log('disabledSubmit', disabledSubmit);
        if (disabledSubmit) {
          //          if (!isOneChange.current) {
          //            isOneChange.current = true;
          setSyncState(false);
          // formik.setFieldValue('synchronized', false, false);
          //          }
        } else {
          // console.log('syncState', syncState);
          // if (!syncState) {
          //            isOneChange.current = false;
          setSyncState((prevState) => (!prevState ? syncDefault : prevState));
          // formik.setFieldValue('synchronized', syncDefault, false);
          // }
        }
      }
      console.groupEnd();
    }
  }, [formik.initialValues.synchronized, formik.values, disabledSubmit]);

  React.useEffect(() => {
    if (actList && Array.isArray(actList.actList)) {
      if (!actList.actList.length) onClose();
    }
  }, [actList?.actList]);

  return (
    <Dialog
      {...setupDialogStyle}
      open={open}
      onClose={(ev, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}>
      <FormikProvider value={formik}>
        <Wrapper>
          <Header>
            <Typography variant="h6" fontSize={'16px'}>
              Изменение параметров расчета актов
            </Typography>
            <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
              {!!renderActs.length && (
                <React.Fragment>
                  <SelectField>
                    Текущий акт:
                    <StyledSelect
                      smallText
                      hideBorder
                      width={240}
                      onClear={() => {
                        alert('clear');
                      }}
                      nullableValues={[renderAct]}
                      SelectProps={{
                        renderValue: () => {
                          const separator = ' — ';
                          const destructiveStr = renderAct.split(separator);
                          destructiveStr[1] = `по ${destructiveStr[1]}`;
                          return 'с ' + destructiveStr.join(separator);
                        },
                        MenuProps: {
                          sx: {
                            maxHeight: 500,
                            width: 240
                          }
                        },
                        value: renderAct,
                        placeholder: 'Выберите диапазон'
                      }}
                      value={renderAct}
                      placeholder={'Выберите диапазон'}
                      fullWidth
                      select>
                      {renderActs}
                    </StyledSelect>
                  </SelectField>

                  <SelectField
                    onChange={(event) => {
                      if ('checked' in event.target) {
                        setSyncState((prevState) => !prevState);
                        // formik.setFieldValue(
                        //   'synchronized',
                        //   event.target.checked,
                        //   true
                        // );
                      }
                      // methods.setValue('synchronized', !props.value, {
                      //   shouldValidate: true
                      // });
                    }}>
                    <Checkbox checked={syncState} size={'small'} />
                    Применить параметры как в расчете
                  </SelectField>
                </React.Fragment>
              )}
              <BtnWrapper>
                <Button
                  disabled={requestInfo.isLoading || !disabledSubmit}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  color="success">
                  {requestInfo.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Применить'
                  )}
                </Button>
                <Button
                  disabled={requestInfo.isLoading}
                  color="primary"
                  onClick={handleClose}>
                  Закрыть
                </Button>
              </BtnWrapper>
            </Stack>
          </Header>
          <ParametersDialogTabs
            disabled={!renderActs.length}
            onChange={onChange}
            tab={tab}
          />
          <Content
            tab={tab}
            setCurrentAct={(act) => {
              changeCurrentAct(act);
              firstLoad.current = true;
            }}
          />
          {/*<Table estimates={responseData?.estimates} />*/}
        </Wrapper>
        <ConfirmDialog />
        <ChangeConfirmDialog />
      </FormikProvider>
    </Dialog>
  );
};
