import React, { FC, useCallback, useContext, useState } from 'react';
import {
  INDEX_METHOD_TAB_VALUES,
  IndexMethodHeaderProps,
  IndexMethodSearchParamsTab,
  Stepper
} from '.';
import { Box, Menu, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CopyDrawer from '../../Calculations/components/CalculationDirectory/components/CopyDrawer';
import { DrawerEdit } from 'pages/Calculations/components/EditCalculation';
import { useDeleteCalculationMutation } from 'api/calculations';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { deleteData, openDB } from 'utils/indexDB';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useTranslation } from 'react-i18next';
import { CombineComponent } from './components';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StepperBlock from 'components/Stepper';
import { ReactComponent as SuccessIcon } from 'assets/icons/cloudSuccessWhite.svg';
import IntegrateDrawer from '../../Calculations/components/CalculationСomplicated/components/IntegrateDrawer/IntegrateDrawer';
import { ImportButton } from '../../Administration/AdminReferences/Prices/components/PricesReferences.style';
import Button from '../../../components/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useStepperContext } from '../../../hooks/useStepper';

const getKeyByIndex = (
  index: number
): IndexMethodSearchParamsTab | undefined => {
  const keys = Object.keys(INDEX_METHOD_TAB_VALUES);
  return keys[index] as IndexMethodSearchParamsTab;
};

export const IndexMethodHeader: FC<IndexMethodHeaderProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();

  // const { maxDepth, depth, toggleDepth } = useContext(
  //   StepperIndexMethodContext
  // );

  const { maxDepth, depth, toggleDepth } = useStepperContext<Stepper>();

  const [integrateDrawer, setIntegrateDrawer] = useState(false);

  const toggleIntegrate = (flag: boolean) => {
    setIntegrateDrawer(flag);
  };

  // * Tab * //
  const handleSelectedTab = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    const newTab = getKeyByIndex(newValue);
    if (newTab) props.setTab(newTab);
  };

  const tabIndex = Object.keys(INDEX_METHOD_TAB_VALUES).indexOf(props.tab);

  // * Edit Calculation * //
  const [editCalc, setEditCalc] = useState(false);
  const handleOpenEditCalc = () => {
    setEditCalc(true);
  };

  // * Copy Calculation * //
  const [copyDrawer, setCopyDrawer] = useState(false);
  const toggleOpenCopy = useCallback(
    (copy?: boolean) => setCopyDrawer((prevState) => (copy ? copy : prevState)),
    []
  );

  // * Menu * //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenuCalculation = Boolean(anchorEl);
  const handleCloseMenuCalculation = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpenMenuCalculation = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => setAnchorEl(event.currentTarget);

  // * Delete Calculation * //
  const isDisabledDeleteCalculation = false;
  const isExecution = props.tab === 'execution';

  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();

  const handleConfirmDelete = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        deleteCalculation({
          projectID: props.projectID,
          calcID: props.calcID
        }).then(() => {
          openDB().then((db) => deleteData(db, props.calcID));
        });
      }
    },
    [deleteCalculation, props.calcID, props.projectID]
  );
  const { ConfirmDialog: ConfirmDialogDelete, openConfirm: openConfirmDelete } =
    useConfirmDialog({
      title: 'Вы уверены?',
      body: 'Расчёт будет удалён и восстановить его будет невозможно',
      handleConfirm: handleConfirmDelete
    });

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${props.projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success'
    });
  });

  // * Parameters Calculation * //
  const [calcParameters, setCalcParameters] = useState(false);

  const openCalcParameters = () => {
    setCalcParameters(true);
  };

  const closeCalcParameters = useCallback(() => {
    setCalcParameters(false);
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch">
        <Stack direction="row" flexGrow={1}>
          <Stack
            spacing={5}
            flexGrow={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Tabs value={tabIndex} onChange={handleSelectedTab}>
              {Object.values(INDEX_METHOD_TAB_VALUES).map((value, index) => (
                <Tab key={index} label={value} />
              ))}
            </Tabs>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          {(tabIndex === 0 || tabIndex === 3) && (
            <StepperBlock
              count={
                tabIndex === 0 ? maxDepth.indexPage : maxDepth.executedPage
              }
              page={tabIndex === 0 ? depth.indexPage : depth.executedPage}
              onChange={(ev, page) => {
                toggleDepth(
                  tabIndex === 0 ? 'indexPage' : 'executedPage',
                  page
                );
              }}
            />
          )}
          {props.dataCalculation?.integrationInfo && (
            <Button
              disabled={!props.currentAct}
              onClick={() => toggleIntegrate(true)}
              style={{
                padding: '8px',
                minWidth: '40px'
              }}>
              <SuccessIcon />
            </Button>
          )}
          <ImportButton
            variant={'text'}
            disabled={isExecution ? !props.currentAct : false}
            onClick={openCalcParameters}>
            <div>
              <FormatListBulletedIcon />
              Параметры {isExecution ? 'актов' : 'расчета'}
            </div>
          </ImportButton>
          {/*<Button*/}
          {/*  style={{*/}
          {/*    height: '40px',*/}
          {/*    gap: '8px'*/}
          {/*  }}*/}
          {/*  disabled={isExecution ? !props.currentAct : false}*/}
          {/*  variant="header"*/}
          {/*  onClick={openCalcParameters}>*/}
          {/*  <FormatListBulletedIcon />*/}
          {/*  /!*Параметры расчета*!/*/}
          {/*  */}
          {/*</Button>*/}
          <Button
            onClick={handleOpenMenuCalculation}
            style={{
              height: '40px',
              padding: '8px',
              minWidth: '40px'
            }}>
            <MoreHorizIcon />
          </Button>
        </Stack>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenuCalculation}
        onClose={handleCloseMenuCalculation}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          marginTop: '10px'
        }}>
        <MenuItem
          onClick={() => {
            toggleOpenCopy(true);
            handleCloseMenuCalculation();
          }}>
          Создать копию расчета
        </MenuItem>
        <MenuItem
          onClick={() => {
            openConfirmDelete();
            handleCloseMenuCalculation();
          }}
          disabled={isDisabledDeleteCalculation}>
          Удалить расчет
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenEditCalc();
            handleCloseMenuCalculation();
          }}>
          Редактировать
        </MenuItem>
      </Menu>
      {calcParameters && (
        <CombineComponent
          updateFragment={props.updateFragment}
          calculation={props.dataCalculation}
          changeCurrentAct={props.changeCurrentAct}
          currentAct={props.currentAct}
          actList={props.actList}
          refetchActs={props.refetchActs}
          isExecution={isExecution}
          open={calcParameters}
          onClose={closeCalcParameters}
        />
      )}
      <IntegrateDrawer
        actList={props.actList?.actList || []}
        changeAct={props.changeCurrentAct}
        act={props.currentAct}
        open={integrateDrawer}
        close={() => {
          toggleIntegrate(false);
        }}
        calculation={props.dataCalculation}
      />
      <CopyDrawer
        onClose={() => setCopyDrawer(false)}
        open={copyDrawer}
        calculation={props.dataCalculation}
      />
      <DrawerEdit
        onClose={(b) => setEditCalc(b)}
        open={editCalc}
        calculation={props.dataCalculation}
      />
      <ConfirmDialogDelete />
    </Box>
  );
};
