/**
 * @author Mr_Fabiozzz[mr.fabiozzz@gmail.com]
 */
import { ICellEditorParams } from 'ag-grid-community';
import { PopperComponent } from 'components/PopperComponent';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyledTooltip } from '../CellEditorSergey/CellEditor.style';
import { InputEdit } from './Cell.style';

const EditableCell = forwardRef<any, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState<string | number>(
    props.value?.toString()?.replace(/\./, ',') || ''
  );
  const refPopper = React.useRef<HTMLDivElement | null>(null);

  const {
    control,
    setValue: setFormValue,
    formState
  } = useForm({
    mode: 'all'
  });
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        return value === '' || value === undefined ? null : value;
      },
      isCancelAfterEnd() {
        return !formState.isValid || !formState.isDirty || value === '-';
      }
    }),
    [formState.isValid, formState.isDirty, value]
  );

  useEffect(() => {
    props?.eGridCell?.getElementsByTagName('input')[0]?.select();
    props?.eGridCell?.getElementsByTagName('input')[0]?.focus();
  }, []);

  return (
    <Controller
      control={control}
      name={'input'}
      defaultValue={value}
      rules={{
        validate: {
          max: (v: string) => {
            if (!v) return true;
            const replacedValue = v.replace(/\s/g, '');
            const dotIndex = replacedValue.indexOf(',');
            const [MAX_LENGTH_LEFT, MAX_LENGTH_RIGHT] = [9, 6];
            console.log(replacedValue.substring(dotIndex + 1));
            if (dotIndex >= 0) {
              if (
                replacedValue.substring(0, dotIndex).length > MAX_LENGTH_LEFT ||
                replacedValue.substring(dotIndex + 1).length > MAX_LENGTH_RIGHT
              ) {
                return 'Максимальное значение 999 999 999,999999';
              }
            } else {
              if (replacedValue.length > MAX_LENGTH_LEFT) {
                return 'Максимальное значение 999 999 999,999999';
              }
            }
            return true;
          }
        }
      }}
      render={({ formState }) => {
        const errorMessage =
          (formState.errors['input']?.message &&
            formState.errors['input']?.message.toString()) ||
          '';
        return (
          <>
            <StyledTooltip
              ref={refPopper}
              title={errorMessage}
              color={'error'}
              placement="right"
              open={!!errorMessage}>
              {/*<Box display={'flex'} width={'calc(100% - 16px)'} height={'100%'}>*/}
              <InputEdit
                onFocus={(e: { target: { select: () => void } }) =>
                  e.target.select()
                }
                allowedDecimalSeparators={[',', '.']}
                decimalSeparator={','}
                style={{
                  // borderBottom: `1px solid ${!!errorMessage ? '#e53935' : '#3f51b5'}`,
                  borderBottom: `1px solid #3f51b5`
                  // ...(!!errorMessage && {
                  //   background: 'rgba(229,57,53,0.09)',
                  //   color: '#e53935'
                  // })
                }}
                allowNegative
                // onValueChange={(event) => {
                //   setValue(() => event.formattedValue);
                //   setFormValue('input', event.formattedValue, {
                //     shouldValidate: true
                //   });
                // }}
                onChange={(event) => {
                  setValue(() => event.target.value);
                  setFormValue('input', event.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                }}
                autoFocus
                thousandSeparator={' '}
                value={value}
                onBlur={() => props.stopEditing(true)}
              />
              {/*</Box>*/}
            </StyledTooltip>
            <PopperComponent
              message={errorMessage as string}
              refItem={refPopper.current}
            />
          </>
        );
      }}
    />
  );
});
EditableCell.displayName = 'EditableCell';
export default EditableCell;
