import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { appendData, getData } from './actions';
import { IInitBimCalcState } from './bim.types';

const initialState: IInitBimCalcState = {
  list: [],
  filterList: [],
  total: [],
  loader: false,
  endFetch: false,
  page: 0,
  hiddenRows: new Set(),
  reset: false
};

export const bimSliceCalculation = createSlice({
  name: 'bimSliceCalculation',
  initialState,
  reducers: {
    reset(state) {
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.total = [];
    },
    setHiddenRows(state, action: PayloadAction<Set<number>>) {
      state.hiddenRows = action.payload;
    },
    initList(
      state,
      action: PayloadAction<{
        list: IInitBimCalcState['list'];
        total: IInitBimCalcState['total'];
      }>
    ) {
      console.log('init', action.payload);
      state.list = action.payload.list;
      state.total = action.payload.total;
      state.endFetch = false;
    },
    togglePage(state, action: PayloadAction<number | undefined>) {
      state.page =
        action.payload !== undefined ? action.payload : state.page + 1;
    },
    appendRows(state, action: PayloadAction<IInitBimCalcState['list']>) {
      console.log('append', action.payload);
      state.list = state.list.concat(action.payload);
    },
    addByID(
      state,
      action: PayloadAction<{ id: number; data: IInitBimCalcState['list'] }>
    ) {
      const targetIndex = state.list.findIndex(
        (el) => el.id === action.payload.id
      );
      if (targetIndex >= 0) {
        state.list.splice(targetIndex + 1, 0, ...action.payload.data);
      }
    },
    removeByID(state, action: PayloadAction<number>) {
      const ids: number[] = [action.payload];
      function flatFilter(
        children: IInitBimCalcState['list'],
        targetLevel: number
      ) {
        children.forEach((el) => {
          if (ids.includes(el.parentID!)) {
            if (el.children && el.level! > targetLevel) {
              ids.concat(el.children.map((item) => item.id));
              flatFilter(el.children!, targetLevel);
            }
          }
        });
      }
      const targetIndex = state.list.findIndex(
        (el) => el.id === action.payload
      );
      if (targetIndex >= 0) {
        const target = state.list[targetIndex];
        flatFilter(target.children, target.level!);
        state.list = state.list.filter((el) => !ids.includes(el.parentID!));
      }
    },
    filterByID(state, action: PayloadAction<number>) {
      const ids = new Map<number, number>();
      ids.set(action.payload, action.payload);
      const newList: IInitBimCalcState['list'] = [];
      for (let i = 0; i < state.list.length; i++) {
        const el = state.list[i];
        if (!ids.has(el.parentID!) || el.parentID === null) {
          newList.push(el);
        } else {
          ids.set(el.id, el.id);
        }
        // if (state.list[i].type === clickTarget.type) break;
      }
      console.log(newList);
      state.filterList = newList;
      // function flatFilter(
      //   children: IInitBimCalcState['list'],
      //   targetLevel: number
      // ) {
      //   children.forEach((el) => {
      //     if (ids.includes(el.parentID!)) {
      //       if (el.children && el.level! > targetLevel) {
      //         ids.concat(el.children.map((item) => item.id));
      //         flatFilter(el.children!, targetLevel);
      //       }
      //     }
      //   });
      // }
      // const targetIndex = state.list.findIndex(
      //   (el) => el.id === action.payload
      // );
      // if (targetIndex >= 0) {
      //   const target = state.list[targetIndex];
      //   flatFilter(target.children, target.level!);
      //   state.list = state.list.filter((el) => !ids.includes(el.parentID!));
      // }
    },
    toggleLoader(state, action: PayloadAction<boolean | undefined>) {
      if (action.payload !== undefined) {
        state.loader = action.payload;
      } else {
        state.loader = !state.loader;
      }
    },
    clear(state) {
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.total = [];
    },
    toggleEndFetch(state, action: PayloadAction<boolean | undefined>) {
      state.endFetch =
        action.payload !== undefined ? action.payload : !state.endFetch;
    }
  },
  extraReducers(builder) {
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      state.list = action.payload.data;
      state.total = [action.payload.total];
    });
    builder.addCase(appendData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      state.list = state.list.concat(action.payload.data);
      state.total = [action.payload.total];
    });
  }
});
export const getBimCalcState = (state: RootState) =>
  state.calculations.bim.calc;
export const {
  reset,
  togglePage,
  clear,
  initList,
  removeByID,
  appendRows,
  addByID,
  toggleLoader,
  filterByID,
  toggleEndFetch,
  setHiddenRows
} = bimSliceCalculation.actions;
export default bimSliceCalculation.reducer;
