import { ColDef, ColGroupDef, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import Progress from 'components/Progress';
import {
  ENUMLocalStorage,
  getLocalStorage,
  useLocalStorage
} from 'hooks/use-local-storage';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  ComplicatedDynamicRow,
  ComplicatedRimRow,
  IIndexMethodTable
} from 'types/index-method/index-method';
import { v4 as uuid4 } from 'uuid';
import {
  CellRendererIndexMethod,
  customHeader,
  customHeaderMultiline,
  getHeadersDefaultIndexMethodCalculation,
  getRowClassIndexMethodCalculation,
  HeaderComponentWithFilter,
  headerRendererIndexMethod,
  IIndexMethodCalculationContext,
  IndexMethodCalculationTableProps,
  TChips
} from '.';
import { useGetCalculationCalcIDComplicatedPreviewQuery } from '../../../api/calculations';

import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Tooltip from 'components/Tooltip';
import {
  Chip,
  ChipWrapper,
  Devide
} from 'pages/Administration/AdminReferences/Prices/components/PricesReferences.style';
import { RimRequest } from '../../../api/calculations/types';
import { usePagination } from '../../../hooks/use-pagination';
import { useStepperContext } from '../../../hooks/useStepper';
import { setGridRef } from '../../../store/slices/ag-grid/ag-grid-slice';
import {
  appendData,
  getData
} from '../../../store/slices/calculations/rim/actions';
import {
  getRimCalcState,
  reset as resetTable,
  setHiddenRows,
  togglePage
} from '../../../store/slices/calculations/rim/rim.slice';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { Stepper } from '../index-method';
import {
  IndexMethodCalculationDialogFilter,
  title
} from './index-method-calculation.dialog-filter';

const defaultColDef = { resizable: true };
const LIMIT = 10000;
export const IndexMethodCalculation: FC<IndexMethodCalculationTableProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const {
    list,
    total,
    limitedRows,
    hiddenRows,
    endFetch,
    page,
    loader,
    reset
  } = useTypedSelector(getRimCalcState);

  const [table, setTable] = useState<undefined | ComplicatedRimRow[]>();

  const { setMaxDepth, depth } = useStepperContext<Stepper>(function () {
    // if (!props.firstLoad) {
    //   const hideRows =
    //     data
    //       ?.filter((el) => {
    //         return el.lvl && el.lvl >= depth.indexPage;
    //       })
    //       ?.map((_) => _.id! as number) ?? [];
    //   setCollapseRowsIds(hideRows);
    //   ref.current?.api?.setIsExternalFilterPresent(() => true);
    //   ref.current?.api?.onFilterChanged();
    // }
  }, 'indexPage');
  // code?: string[]   header?: string[]   lsr?: string[]   title?: string[]   unit?: string[]

  useEffect(() => {
    if (!table || !table.length) {
      setTable(list);
    }
  }, [list]);

  const [filterDrawer, setFilterDrawer] = React.useState<keyof TChips | null>(
    null
  );
  const [bodyParams, setBodyParams] = React.useState<Required<TChips>>({
    lsr: [],
    chapter: [],
    header: [],
    code: [],
    title: [],
    unit: []
  });
  console.log('bodyParams', bodyParams);
  const onChangeParams = React.useCallback(
    (key: keyof TChips, target: string | string[]) => {
      setBodyParams((prevState) => {
        const newState: typeof prevState = {
          ...prevState
        };
        if (typeof target === 'string') {
          newState[key] = newState[key].includes(target)
            ? newState[key].filter((el) => el !== target)
            : newState[key].concat(target);
        } else if (Array.isArray(target)) {
          newState[key] = target;
        }
        return newState;
      });
    },
    []
  );

  useEffect(() => {
    dispatch(resetTable());
  }, [bodyParams]);

  const ref = useRef<AgGridReact<IIndexMethodTable> | null>(null);
  const levelID = useMemo(
    () => Number(props.calcID || props.fileID || 0),
    [props.calcID, props.fileID]
  );
  const { setValue: setLevelsNotPreview, firstValue: firstLevelsNotPreview } =
    useLocalStorage(ENUMLocalStorage.levelsIndexMethod, []);
  const { setValue: setLevelsPreview, firstValue: firstLevelsPreview } =
    useLocalStorage(ENUMLocalStorage.levelsIndexMethodPreview, []);

  const setLevels = useMemo(
    () => (props.mode === 'editor' ? setLevelsNotPreview : setLevelsPreview),
    [props.mode, setLevelsNotPreview, setLevelsPreview]
  );
  const firstLevels = useMemo(
    () =>
      props.mode === 'editor' ? firstLevelsNotPreview : firstLevelsPreview,
    [firstLevelsNotPreview, firstLevelsPreview, props.mode]
  );

  const {
    data: previewData,
    isFetching: isFetchingCalculationComplicated,
    isLoading: previewLoading
  } = useGetCalculationCalcIDComplicatedPreviewQuery(
    {
      type: 'rim',
      isPreview: true,
      fileID: props.fileID,
      projectID: props.projectID
    },
    { skip: props.mode === 'editor' }
  );

  // const [getDataOld, { data: calcData, isLoading: calcLoading }] =
  //   useGetCalculationCalcIDComplicatedMutation();

  const dataCalculationComplicated = useMemo(() => {
    if (props.mode === 'editor') {
      return list;
    }
    return previewData;
  }, [previewData, props.mode]);
  const isLoadingCalculationComplicated = useMemo(() => {
    if (props.mode === 'editor') {
      return loader;
    }
    return previewLoading;
  }, [previewLoading, props.mode]);

  // useLayoutEffect(() => {
  //   console.log(props.updateFragment);
  //   if (props.mode === 'editor') {
  //     console.log(props.updateFragment);
  //     getDataOld({
  //       calcID: props.calcID,
  //       type: 'rim',
  //       page: 0,
  //       limit: 200,
  //       isPreview: false,
  //       body: bodyParams
  //     });
  //   }
  // }, [props.calcID, props.mode, bodyParams, props.updateFragment]);

  const isLoading = useMemo(
    () => isFetchingCalculationComplicated || isLoadingCalculationComplicated,
    [isFetchingCalculationComplicated, isLoadingCalculationComplicated]
  );

  const renderLimitedRows: ComplicatedRimRow[] = useMemo(() => {
    //limit-header
    const limited =
      props.mode === 'editor'
        ? limitedRows
        : (previewData?.limitedCostsAsRows ?? []);
    const d: ComplicatedRimRow[] = [
      {
        id: -limited[0]?.id,
        type: 'limit-header',
        title: 'лимитированные',
        hasChildren: false,
        including: {
          building: null,
          equipment: null,
          mounting: null,
          other: null
        },
        lvl: 1
      }
    ];
    if (limited.length) {
      return d.concat(
        limited.map((el) => ({
          ...el,
          id: uuid4(),
          type: 'limit'
        }))
      );
    }
    return [];
  }, [limitedRows, previewData]);

  // const data: IIndexMethodTable[] = useMemo(() => {
  //   let lvl = 1;
  //   const mappedData =
  //     dataCalculationComplicated?.data.map((e) => {
  //       if (e.lvl > lvl) {
  //         lvl = e.lvl;
  //         if (e.hasChildren) {
  //           lvl = e.lvl + 1;
  //         }
  //       }
  //       return {
  //         ...e
  //       };
  //     }) ?? [];
  //   setMaxDepth('indexPage', lvl);
  //
  //   return mappedData;
  // }, [dataCalculationComplicated?.data]);

  // const defaultData: IIndexMethodTable[] = useMemo(() => {
  //   return (
  //     dataCalculationComplicated?.data.map((e) => ({
  //       ...e
  //     })) ?? []
  //   );
  // }, [dataCalculationComplicated?.data]);

  // const total = useMemo(() => {
  //   return dataCalculationComplicated?.total
  //     ? [dataCalculationComplicated?.total]
  //     : [];
  // }, [dataCalculationComplicated?.total]);

  const totalDynamicRows: ComplicatedDynamicRow[] = useMemo(() => {
    return total ? (total[0]?.dynamicRows ?? []) : [];
  }, [total]);

  const [columnDefs, setColumnDefs] = useState<
    (ColDef<IIndexMethodTable> | ColGroupDef<IIndexMethodTable>)[] | undefined
  >();

  const pinnedTopRowData = useMemo(
    () => (columnDefs?.length ? total : undefined),
    [columnDefs?.length, total]
  );
  // const rowData = useMemo(() => {
  //   if (props.mode === 'editor') {
  //     return columnDefs?.length ? data : undefined;
  //   }
  //
  //   return columnDefs?.length && !isFetchingCalculationComplicated
  //     ? data
  //     : undefined;
  // }, [columnDefs?.length, data, isFetchingCalculationComplicated, props.mode]);

  const [collapseRowsIds, setCollapseRowsIds] = useState<number[]>([]);

  const collapseRows = React.useMemo(() => {
    if (Object.values(bodyParams).flat().length) {
      return [] as typeof collapseRowsIds;
    }
    return collapseRowsIds;
  }, [collapseRowsIds, bodyParams]);

  const onCollapse = useCallback(
    (ids: number[]) => {
      const id = ids[0] as number | string;
      console.log(ids);
      const newSet = new Set(hiddenRows);
      if (hiddenRows.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
        ref.current?.api.forEachNode((n) => {
          if (newSet.has(n.data?.parentID!)) {
            newSet.add(n.data?.id!);
          }
        });
      }
      dispatch(setHiddenRows(newSet));
      // setCollapseRowsIds((prevState) => {
      //   const idsToProcess = ids.flatMap((id) => {
      //     const nestedIds = getNestedChildrenIdsIndexMethodCalculation(
      //       id,
      //       defaultData
      //     );
      //     return Array.from(new Set([id, ...nestedIds]));
      //   });
      //
      //   const isCollapsing = ids.some((id) => prevState.includes(id));
      //
      //   if (isCollapsing) {
      //     // Если элемент уже есть в списке, его дети не исчезают из списка
      //     return prevState.filter((hrId) => !ids.includes(hrId));
      //   } else {
      //     // Если элемент не в списке, добавляем его и его детей в список
      //     return Array.from(new Set([...prevState, ...idsToProcess]));
      //   }
      // });
    },
    [hiddenRows]
  );

  const doesExternalFilterPass = useCallback(
    (params: IRowNode<IIndexMethodTable>) => {
      try {
        ref.current?.api.setIsExternalFilterPresent(() => false);

        if (params.data?.parentID) {
          return !hiddenRows.has(params.data?.parentID);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    [hiddenRows]
  );
  // const doesExternalFilterPass = useCallback(
  //   (params: IRowNode<IIndexMethodTable>) => {
  //     ref.current?.api.setIsExternalFilterPresent(() => false);
  //     const shouldClose = params.data?.parentID
  //       ? !hiddenRows.has(params.data?.parentID!)
  //       : true;
  //     return shouldClose;
  //   },
  //   [hiddenRows.size]
  // );

  const contextTable = useMemo<IIndexMethodCalculationContext>(
    () => ({
      collapseRowsIds: hiddenRows,
      onCollapse,
      toggleDrawer: setFilterDrawer,
      bodyParams
    }),
    [collapseRows, onCollapse, setFilterDrawer, bodyParams]
  );

  useEffect(() => {
    // if (list.length && !isFetchingCalculationComplicated) {
    // console.log('update');
    // setColumnDefs([]);
    // setTimeout(() => {
    if (total[0]?.dynamicRows || previewData?.total?.dynamicRows) {
      setColumnDefs([
        ...getHeadersDefaultIndexMethodCalculation({
          totalDynamicRows:
            props.mode === 'editor'
              ? (total[0]?.dynamicRows ?? [])
              : (previewData?.total?.dynamicRows ?? [])
        })
      ]);
    }
    // }, 200);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    total,
    previewData
    // list.length,
    // totalDynamicRows.length,
    // isFetchingCalculationComplicated,
    // props.updateFragment
  ]);

  useEffect(() => {
    if (isFetchingCalculationComplicated) {
      setColumnDefs(undefined);
      props.toggleFirstLoad(true);
    }
  }, [isFetchingCalculationComplicated]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      if (isLoading) {
        ref.current.api.showLoadingOverlay();
      } else {
        ref.current.api.hideOverlay();
      }
    }
  }, [isLoading, contextTable]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.refreshCells({ force: true, suppressFlash: true });
      ref.current.api.refreshHeader();
    }
  }, [list]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.setIsExternalFilterPresent(() => true);
      ref.current.api.onFilterChanged();
      ref.current.api.refreshCells({ force: true, suppressFlash: true });
      console.log('rerender filtter');
    }
  }, [doesExternalFilterPass]);

  useEffect(() => {
    if (hiddenRows && !props.firstLoad) {
      console.log(collapseRowsIds);
      setLevels(Array.from(hiddenRows) as number[], levelID);
    }
  }, [hiddenRows, levelID]);

  useEffect(() => {
    if (props.firstLoad) {
      const levels = getLocalStorage(ENUMLocalStorage.levelsIndexMethod);
      const isFind = (levels ?? []).find((level) => level.id === levelID);
      console.log(isFind);
      if (isFind) {
        dispatch(setHiddenRows(new Set(isFind.levels)));
        // setCollapseRowsIds(isFind.levels);
        props.toggleFirstLoad(false);
      }
      // else {
      //   if (list && list.length) {
      //     const firstElements = list
      //       .map((item) => (item.parentID === null ? item.id : null))
      //       .filter((item): item is number => item !== null);
      //     if (firstElements && firstElements.length) {
      //       onCollapse(firstElements);
      //     }
      //     props.toggleFirstLoad(false);
      //   }
      // }
    }
  }, [list, levelID, props.calcID, props.fileID]);

  // useEffect(() => {
  // }, [depth.indexPage]);

  const chips = React.useMemo(() => {
    const entries = Object.entries(bodyParams);
    if (!Object.values(bodyParams).flat().length) return <span />;
    return (
      <Stack height={'min-content'} pt={'10px'}>
        <ChipWrapper>
          {entries.map(([key, value]) => {
            return !value.length ? null : (
              <Chip onClick={() => setFilterDrawer(key as keyof TChips)}>
                {title[key as keyof TChips]}
                <Devide />
                <Tooltip title={value.join(',')}>
                  <>{value}</>
                </Tooltip>
                <CloseIcon
                  fontSize={'small'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChangeParams(key as keyof TChips, []);
                  }}
                />
              </Chip>
            );
          })}
        </ChipWrapper>
      </Stack>
    );
  }, [bodyParams]);

  const getDataFn = useCallback((params: RimRequest) => {
    const sendFunction = params.page === 0 ? getData : appendData;
    return sendFunction(params);
  }, []);
  console.log('endFetch', endFetch);
  usePagination<RimRequest>({
    sendFn: getDataFn,
    initialParams: {
      calcID: props.calcID,
      page,
      limit: LIMIT,
      type: 'rim',
      body: bodyParams,
      isPreview: false
    },
    thenFn: () => {
      dispatch(togglePage());
    },
    isAllDataFetched: endFetch,
    requiredDeps: [props.calcID],
    resetToInitialDeps: [bodyParams, reset]
  });

  useEffect(() => {
    if (ref.current) {
      dispatch(setGridRef(ref.current));
    }
  }, [ref.current]);
  console.log('list', list);
  return (
    <div
      style={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr',
        gap: '10px'
      }}>
      {chips}
      <WrapperAgGrid
        style={{ height: '100%' }}
        className="ag-theme-material reference-prices index-methods-calculation">
        <AgGridReact<IIndexMethodTable>
          ref={ref}
          context={contextTable}
          defaultColDef={defaultColDef}
          singleClickEdit
          columnDefs={columnDefs}
          getRowClass={getRowClassIndexMethodCalculation}
          gridOptions={{
            components: {
              cellRenderer: CellRendererIndexMethod,
              headerComponent: headerRendererIndexMethod,
              customHeader,
              customHeaderMultiline,
              headerWithFilters: HeaderComponentWithFilter
            }
          }}
          pinnedTopRowData={pinnedTopRowData}
          rowData={
            props.mode === 'editor'
              ? table?.concat(renderLimitedRows)
              : previewData?.data?.concat(renderLimitedRows)
            // list.concat(renderLimitedRows)
            // calcData?.data ?? []
            // rowData={
            //   isFetchingCalculationComplicated
            //     ? undefined
            //     : [...(rowData || []), ...limitedRows]
          }
          getRowId={(params) => (params.data.id || '').toString()}
          getRowHeight={(params) => {
            if (params.data?.type === 'limit-header') {
              return 30;
            }
            if (params.node.rowPinned === 'top') {
              return 50;
            }
            return 55;
          }}
          rowStyle={{
            padding: '0 !important'
          }}
          getRowStyle={(params) => {
            if (params.data?.type === 'limit-header') {
              return {
                'pointer-events': 'none'
              };
            }
            return;
          }}
          suppressCellFocus
          enableCellTextSelection
          ensureDomOrder
          maintainColumnOrder
          rowHeight={55}
          groupHeaderHeight={40}
          headerHeight={46}
          doesExternalFilterPass={doesExternalFilterPass}
          loadingOverlayComponent={Progress}
          noRowsOverlayComponent={Progress}
          onFirstDataRendered={(event) => {
            event.api.sizeColumnsToFit();
            event.api.setIsExternalFilterPresent(() => true);
            event.api.onFilterChanged();
          }}
          // onGridSizeChanged={(event) => {
          //   event.api.sizeColumnsToFit();
          // }}
          // onViewportChanged={(event) => {
          //   event.api.sizeColumnsToFit();
          // }}
        />
      </WrapperAgGrid>
      <IndexMethodCalculationDialogFilter
        open={filterDrawer}
        activeFilters={bodyParams}
        changeFilter={onChangeParams}
        onClose={() => setFilterDrawer(null)}
      />
    </div>
  );
};
