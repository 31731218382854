/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import {
  calculationDictionaryApi,
  IBodyCreateVorFromPosition,
  IVorPosition,
  IVorPositionsResponse,
  useCreateHandbookVorFromPositionMutation,
  useGetHandbookVorPositionsQuery
} from 'api/calculationDictionary';
import {
  PositionVOR,
  PositionVORWithTarget,
  UnitType
} from 'api/calculations/types';
import Button from 'components/Button';
import { CalculationLabel } from 'components/CalculationLabel';
import { ProjectLabel } from 'components/ProjectLabel';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { useCalcId } from 'hooks/useCalcId';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useProjectId } from 'hooks/useProjectId';
import { isNull } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  changeModalState,
  changeSavingData,
  filterWorks,
  setExtraWorks,
  setMainWorks,
  setVor,
  updateDeletedRows
} from 'store/slices/vor/vor';
import { RootState, useAppDispatch } from 'store/store';
import { useGetVorQuery } from '../../../../../../api/calculations';
import Tooltip from '../../../../../../components/Tooltip';
import useConfirmDialog from '../../../../../../hooks/useConfirmDialog';
import FullVor from '../../../../../FullVor/FullVor';
import { VorTableVariant } from '../../../../../Vor';
import { ModalVOR } from '../../../../../Vor/components/ModalVOR';
import { PositionVorContext } from '../../../../../Vor/components/PositionsTable';
import {
  StyledCount,
  StyledToggleButton,
  StyledToggleButtonWrapper,
  TableLegendFormButton
} from '../../../../../Vor/components/TableLegend';
import { toggleButtons } from '../../../../../Vor/components/TableLegend/TableLegend.constants';
import {
  PageStyled,
  WrapperAgGrid
} from '../../../Accomplishment/Accomplishment.styles';
import CalcMenu from '../../components/ProjectMenu';
import { HandbookContext } from '../../handbook.services';
import { Parameters } from '../CalculationTab/Parameters';
import {
  defaultProps,
  generateLevels,
  IVorTabProps,
  useTable
} from './VorTab.types';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { Divider } from '../../../../../../components/Divider';

const VorTab: React.FC<IVorTabProps> = ({
  calculation,
  openEdit,
  isHandbook = true
}) => {
  const Ref = useRef<AgGridReact<IVorPosition> | null>(null);
  const dispatch = useAppDispatch();

  const calcID = useCalcId();
  const projectId = useProjectId();
  const [collapseColumns, setCollapseColumns] = React.useState(
    !!localStorage.getItem('collapse')
  );
  const {
    isAddingVor,
    savingData,
    selectedPositions,
    grouped,
    vorId,
    deletedRows
  } = useSelector((state: RootState) => state.vor.table);

  const [createVorFromPosition, createVorResponse] =
    useCreateHandbookVorFromPositionMutation();

  const { ConfirmDialog: ConfirmCreateVor, openConfirm: openConfirmCreateVor } =
    useConfirmDialog({
      title: 'Подтвердить формирование расценки?',
      body: 'По выбранным позициям будут сформированы единичные расценки.',
      handleConfirm(confirm, fn) {
        if (confirm) {
          fn?.();
        }
      }
    });

  const {
    buttonContainer,
    currentAct,
    toggleIntegrate,
    anchorEl,
    setAnchorEl,
    parametersDialogOpen,
    setParametersDialogOpen
  } = React.useContext(HandbookContext);
  const [selectedTableVariant, setSelectedTableVariant] =
    useState<VorTableVariant>('positions');
  const [selectedVor, setSelectedVor] = useState<IVorPosition | null>(null);
  const [selectUnit, setSelectUnit] = React.useState<UnitType>();

  const [selectPositions, setSelectPositions] = React.useState<IVorPosition[]>(
    []
  );
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const [delArrId, setDelArrId] = React.useState<number[]>([]);

  const selectPosition = React.useCallback(
    (
      target: IVorPosition[] | IVorPosition,
      type: IVorPosition['type'],
      arr: IVorPosition[]
    ) => {
      // let newArray: TSelectPositionArr = JSON.parse(JSON.stringify(selectPositions.length?selectPositions:selectPositionsStorage.current));
      // console.log(arr);
      let newArray: IVorPosition[] = arr.map((_) => ({ ..._ }));
      // console.log(newArray)
      // console.log(type)
      if (Array.isArray(target)) {
        if (type === undefined) {
          newArray = target;
        } else {
          target.forEach((item) => {
            const findPosition = newArray.findIndex((p) => item.id === p.id);
            if (findPosition >= 0) {
              const itemArray = newArray[findPosition];
              if (type !== undefined) {
                newArray[findPosition] = { ...itemArray, type } as IVorPosition;
              } else {
                newArray.filter((_) => _.id !== item.id);
              }
            } else {
              if (type !== undefined) {
                newArray.push({ ...item, type });
              }
            }
          });
        }
      } else {
        const findPosition = newArray.findIndex((p) => target.id === p.id);
        if (findPosition >= 0) {
          if (type !== undefined) {
            newArray.splice(findPosition, 1, { ...target, type });
          } else {
            if (type === undefined) {
              // newArray[findPosition] = {...target, type}
            } else {
              newArray.splice(findPosition, 1);
            }
          }
        } else {
          if (type !== undefined) {
            newArray.push({ ...target, type });
          }
        }
      }
      setSelectPositions(newArray);
      // selectPositionsStorage.current = newArray;
    },
    [selectPositions]
  );
  const { data: currentVor } = useGetVorQuery(
    {
      calcId: calcID,
      vorId,
      isHandbook: true
    },
    { skip: !calcID || !vorId }
  );
  const isMainWorks = React.useMemo(() => {
    return selectPositions.filter((item) => {
      return item.type === 'main';
    }).length;
  }, [selectPositions]);

  const selectedWorks = React.useMemo(() => {
    return selectPositions.filter((item) => {
      return item.type === 'main' || item.type === 'extra';
    }).length;
  }, [selectPositions]);

  const update = React.useCallback(
    (vor: IVorPosition | IVorPosition[], type: IVorPosition['type']) => {
      if (!Ref.current) return false;
      // console.log(vor, type);
      selectPosition(
        vor as IVorPosition | IVorPosition[],
        type,
        selectPositions as IVorPosition[]
      );
      // Ref.current?.api.applyTransaction({ update: [{ ...vor, type }] });
      // Ref.current?.api?.refreshCells({ force: true });
    },
    [Ref.current, selectPosition]
  );

  const { data } = useGetHandbookVorPositionsQuery(
    { calcID },
    { skip: !calcID }
  );

  const table: IVorPositionsResponse = React.useMemo(() => {
    if (!data)
      return {
        data: { elements: [], dynamicLen: 0 },
        formed: { elements: [], dynamicLen: 0 },
        units: { data: [], formed: [] }
      } as IVorPositionsResponse;
    const newData = (data?.data?.elements || []).map((item) => {
      const newItem: typeof item = JSON.parse(JSON.stringify(item));
      newItem.dynamicItems = generateLevels(
        newItem.dynamicItems,
        data.data.dynamicLen < 3 ? 3 : data.data.dynamicLen
      );
      return newItem;
    });
    const formed = (data?.formed?.elements || []).map((item) => {
      const newItem: typeof item = JSON.parse(JSON.stringify(item));
      newItem.dynamicItems = generateLevels(
        newItem.dynamicItems,
        data.formed.dynamicLen < 3 ? 3 : data.formed.dynamicLen
      );
      return newItem;
    });
    // console.log(newData);
    return {
      ...data,
      data: {
        ...data.data,
        elements: newData
      },
      formed: {
        ...data.formed,
        elements: formed
      }
    };
  }, [data]);
  // console.log(table);
  const countGroupedPositions = React.useMemo(
    () => table.formed.elements.length,
    [table.formed]
  );
  const countPositions = React.useMemo(
    () => table.data.elements.length,
    [table.data]
  );
  // const countGroupedPositions = /*groupedPositions.length;*/ table.formed.length;
  // const countPositions = /*vorPositions.length*/ table.data.length;
  const onTableVariantChange = (value: VorTableVariant) => {
    if (value && value !== selectedTableVariant) {
      setSelectedTableVariant(value);
    }
  };

  const columnsDef = useTable(
    update,
    selectedTableVariant,
    selectedTableVariant === 'positions'
      ? (data?.data?.dynamicLen || 0) < 3
        ? 3
        : data?.data?.dynamicLen || 0
      : (data?.formed?.dynamicLen || 0) < 3
        ? 3
        : data?.formed?.dynamicLen || 0
  );

  const onClickVor = useCallback(
    (vor: IVorPosition, t: typeof selectedTableVariant) => {
      if (t === 'grouped') {
        setSelectedVor(vor);
        dispatch(setVor(vor.id));
        setOpenModal(true);
      }
    },
    [dispatch]
  );
  // console.log(selectedTableVariant);

  const tableData = React.useMemo(() => {
    return selectedTableVariant === 'positions'
      ? table.data.elements
      : table.formed.elements;
  }, [selectedTableVariant, table]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      { title: `Расчеты`, url: `/projects/${projectId}/calculations` },
      {
        title: (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        )
      }
    ],
    [calculation, projectId]
  );
  const Content = React.useMemo(() => {
    switch (selectedTableVariant) {
      case 'positions':
      case 'grouped':
        return (
          <WrapperAgGrid className="ag-theme-material reference-prices with-shadow">
            <AgGridReact
              {...defaultProps}
              context={{
                collapseColumns,
                setCollapseColumns: () => {
                  setCollapseColumns((prevState) => !prevState);
                  Ref.current?.api.refreshCells();
                }
              }}
              ref={Ref}
              rowData={tableData}
              columnDefs={columnsDef}
              onRowClicked={(event) => {
                if (event.data) onClickVor(event.data, selectedTableVariant);
              }}></AgGridReact>
          </WrapperAgGrid>
        );
      case 'fullVor':
        return <FullVor isHandbook={true} />;
    }
  }, [
    selectedTableVariant,
    collapseColumns,
    setCollapseColumns,
    tableData,
    columnsDef,
    onClickVor
  ]);
  const { filteredMainWorksOnDelete, filteredExtraWorksOnDelete } =
    React.useMemo(() => {
      return {
        filteredMainWorksOnDelete: selectPositions
          .filter((item) => {
            return item.type === 'main';
          })
          .filter((item) => {
            return !delArrId.includes(item.id);
          }),

        filteredExtraWorksOnDelete: selectPositions
          .filter((item) => {
            return item.type === 'extra';
          })
          .filter((item) => {
            return !delArrId.includes(item.id);
          })
      };
    }, [selectPositions, delArrId]);
  const dispatchVor = React.useCallback(
    (vor: PositionVORWithTarget | IVorPosition, cash: boolean) => {
      selectPosition(
        vor as IVorPosition,
        cash ? null : vor.type,
        selectPositions as IVorPosition[]
      );
      // dispatch(
      //   calculationsApi.util.updateQueryData("getVorPositions", { calcId }, (draft) => {
      //     console.log("clear");
      //     if (draft && draft.data) {
      //       const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === vor.id);
      //       if (changedVorIndex !== undefined && changedVorIndex >= 0) {
      //         if (draft.data[changedVorIndex]) {
      //           (draft.data[changedVorIndex] as any).type = cash ? null : vor.type;
      //         }
      //       }
      //     }
      //   })
      // );
    },
    [selectPositions]
  );

  React.useEffect(() => {
    localStorage.setItem('collapse', JSON.stringify(collapseColumns));
  }, [collapseColumns]);
  React.useLayoutEffect(() => {
    const collapse = JSON.parse(localStorage.getItem('collapse') || 'null');
    if (!isNull(collapse)) {
      setCollapseColumns(collapse);
    } else {
      localStorage.setItem('collapse', JSON.stringify(false));
    }
  }, []);

  React.useEffect(() => {
    // const toggleCityColumn = () => {
    if (tableData && Ref.current && columnsDef.length) {
      const currentColumnState = Ref.current?.columnApi?.getColumnState();
      console.log(currentColumnState);
      let sortIndex: null | number | undefined = null;
      const updatedColumns = currentColumnState?.map((col) => {
        const numDynamicCol = Number(col.colId.replace(/\D/g, ''));
        // console.log(col.colId);
        // console.log(numDynamicCol);
        if (
          (sortIndex === null || sortIndex === undefined) &&
          col.colId.startsWith('dynamicItems') &&
          numDynamicCol >= 3
        ) {
          sortIndex = col.sortIndex;
        }
        if (
          sortIndex !== null &&
          sortIndex !== undefined &&
          col.colId.startsWith('dynamicItems') &&
          numDynamicCol >= 3
        ) {
          return { ...col, hide: !collapseColumns, sortIndex: sortIndex + 1 }; // Переключаем состояние видимости
        }
        return col;
      });
      console.log(updatedColumns);
      // console.log(
      //   updatedColumns?.sort((a, b) =>
      //     (a?.sortIndex ?? 0) > (b?.sortIndex ?? 0) ? 1 : -1
      //   )
      // );
      Ref.current?.columnApi?.applyColumnState({
        // state: updatedColumns
        state: updatedColumns.sort((a, b) =>
          (a?.sortIndex ?? 0) > (b?.sortIndex ?? 0) ? 1 : -1
        ),
        applyOrder: true
      }); // Применяем новое состояние
    }
  }, [collapseColumns, Ref.current, columnsDef, tableData]);

  // console.log(Ref.current?.columnApi?.getColumnState(),Ref.current?.columnApi?.getAllColumns());
  // console.log(selectedTableVariant);

  const handleDelRow = useCallback(
    (vor: IVorPosition | PositionVOR, isEdit?: boolean) => {
      if (isEdit) {
        dispatch(changeSavingData(vor.id));
        dispatch(filterWorks(vor.id));

        const work = table.data.elements.find((_) => _.id === vor.id);
        if (work) {
          dispatchVor(vor as any, true);
        }
        setDelArrId((prevArr) => [...prevArr, vor.id]);
        // console.log(currentVor)
        const deletedRow = currentVor?.rows.find(
          (position: PositionVOR) => position.id === vor.id
        );
        // console.log(deletedRow)
        // console.log(deletedRow);
        if (deletedRow) {
          dispatchVor(deletedRow, true);
        }
        if (deletedRow) {
          dispatch(updateDeletedRows({ rows: [deletedRow] }));
        }
      } else {
        setDelArrId((prevArr) => [...prevArr, vor.id]);
      }
    },
    [table.data, currentVor]
  );
  const addVorHandler = useCallback(() => {
    if (savingData) {
      const main = filteredMainWorksOnDelete;
      const extra = filteredExtraWorksOnDelete;
      const newData = { ...savingData };
      newData.body = {
        ...savingData.body,
        main: [...savingData.body.main, ...main.map((_) => _.id)],
        secondary: [...savingData.body.secondary, ...extra.map((_) => _.id)]
      };
      dispatch(setMainWorks(main));
      dispatch(setExtraWorks(extra));
      dispatch(changeModalState());

      const deletedWorks = [...main, ...extra].filter((item) =>
        deletedRows.find((row) => row.id === item.id)
      );
      if (deletedWorks.length) {
        dispatch(
          calculationDictionaryApi.util.updateQueryData(
            'getHandbookVorPositions',
            { calcID: savingData.calcId },
            (draft) => {
              Object.assign(draft.data.elements, deletedWorks);
            }
          )
        );
      }
      setSelectedTableVariant('grouped');
      setOpenModal(true);
    }
  }, [
    savingData,
    isAddingVor,
    filteredMainWorksOnDelete,
    filteredExtraWorksOnDelete
  ]);
  const abortAdding = () => {
    dispatch(
      calculationDictionaryApi.util.updateQueryData(
        'getHandbookVorPositions',
        { calcID },
        (draft) => {
          if (draft && draft.data) {
            draft.data.elements.forEach((vor: any) => {
              if (vor.type) {
                selectPosition({ ...vor }, null, selectPositions as any[]);
              }
            });
            // const filterWorks = draft.data.filter((_) => _.type);
            // filterWorks.forEach((_) => {
            //   const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === _.id);
            //
            //   if (changedVorIndex !== undefined) {
            //     if (draft.data[changedVorIndex]) {
            //       delete (draft.data[changedVorIndex] as any).type;
            //     }
            //   }
            // });
          }
        }
      )
    );
    setSelectPositions([]);
    dispatch(changeModalState());
    setSelectedTableVariant('grouped');
    setOpenModal(true);
  };

  const handleCreateVorFromPositions = React.useCallback(() => {
    if (calcID) {
      console.log(filteredMainWorksOnDelete);
      const uploadedArr: IBodyCreateVorFromPosition[] =
        filteredMainWorksOnDelete
          .filter((position) =>
            'type' in position ? position?.type === 'main' : false
          )
          .map((position) => {
            const { dynamicItems, id, quantity, total, tzm, tz, num } =
              position;
            return {
              id,
              tz: tz,
              tzm: tzm,
              num,
              dynamicColumns: dynamicItems,
              quantity: quantity!,
              total: total!
            };
          });
      console.log(uploadedArr);
      createVorFromPosition({
        calcID,
        body: {
          targets: uploadedArr
        }
      });
    }
  }, [calcID, filteredMainWorksOnDelete]);

  useEffect(() => {
    if (!openModal && !isAddingVor) {
      setDelArrId([]);
    }
  }, [isAddingVor, openModal]);
  useMutationHandlers(createVorResponse, (response) => {
    setSelectPositions([]);
    const numbers = Object.keys(response.errors);
    if (numbers.length) {
      const temp: Record<string, string[]> = {};
      for (const err in response.errors) {
        const value = response.errors[err];
        if (temp[value]) {
          temp[value].push(err);
        } else {
          temp[value] = [err];
        }
      }
      const messages: string[] = [];
      for (const err in temp) {
        if (err.endsWith('target_unit_null_or_empty')) {
          const suffix = temp[err].length > 1 ? 'иях' : 'ии';
          messages.push(
            `В позиц${suffix} ${temp[err].join(', ')} отсутствует единица измерения`
          );
        }
      }
      enqueueSnackbar(
        <Box display="flex" flexDirection={'column'}>
          {messages.map((message) => (
            <div>{message}</div>
          ))}
        </Box>,
        { variant: 'error', autoHideDuration: 3000 }
      );
    }
  });

  useEffect(() => {
    if (table.data.elements.length || table.formed.elements.length) {
      if (selectedTableVariant === 'positions' && !table.data.elements.length) {
        setSelectedTableVariant('grouped');
      }
      if (selectedTableVariant === 'grouped' && !table.formed.elements.length) {
        setSelectedTableVariant(
          table.data.elements.length ? 'positions' : 'fullVor'
        );
      }
    } else if (!table.data.elements.length && !table.formed.elements.length) {
      setSelectedTableVariant('positions');
    }
  }, [table.data, table.formed, selectedTableVariant]);

  return (
    <>
      <CalcMenu
        isCalc={false}
        openEdit={openEdit}
        anchor={anchorEl}
        setAnchor={setAnchorEl}
        calculation={calculation}
      />
      <PageStyled style={{ gridTemplateRows: 'min-content min-content 1fr' }}>
        <PositionVorContext.Provider
          value={{
            toggleDrawer: () => {},
            selectPositions
            // checkedData    : applyedFilter[type === "group" ? "formedFilter" : "rowsFilter"]
          }}>
          <Box
            display={'flex'}
            padding={'8px 10px'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <ToggleButtonGroup
              sx={{ height: '32px', boxSizing: 'content-box' }}
              exclusive>
              {toggleButtons
                .filter((value, index) => {
                  if (isAddingVor) {
                    if (index === 0) {
                      return value;
                    }
                    return null;
                  }
                  return value;
                })
                .map((button) => (
                  <StyledToggleButtonWrapper key={button.value}>
                    <StyledToggleButton
                      size="small"
                      value={button.value}
                      selected={button.value === selectedTableVariant}
                      disabled={
                        (button.value === 'positions' &&
                          !table.data.elements.length) ||
                        (button.value === 'grouped' &&
                          !table.formed.elements.length) ||
                        (button.value === 'fullVor' &&
                          !table.formed.elements.length)
                      }
                      onChange={(_, value) => onTableVariantChange(value)}>
                      {button.label}
                      {button.value !== 'fullVor' && (
                        <StyledCount>
                          {
                            {
                              positions: countPositions,
                              grouped: countGroupedPositions
                            }[button.value]
                          }
                        </StyledCount>
                      )}
                    </StyledToggleButton>
                  </StyledToggleButtonWrapper>
                ))}
            </ToggleButtonGroup>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              {isAddingVor && (
                <TableLegendFormButton
                  color="warning"
                  disableElevation
                  onClick={abortAdding}>
                  Отмена
                </TableLegendFormButton>
              )}
              {selectedTableVariant === 'positions' && (
                <TableLegendFormButton
                  sx={{
                    '&.Mui-disabled': {
                      background: '#9AA2B0',
                      color: '#fff'
                    }
                  }}
                  disabled={isAddingVor ? !selectedWorks : isMainWorks === 0}
                  onClick={() => {
                    isAddingVor ? addVorHandler() : setOpenModal(true);
                  }}
                  color="success"
                  disableElevation>
                  {isAddingVor
                    ? 'Добавить'
                    : 'Сформировать укрупненную расценку'}
                </TableLegendFormButton>
              )}
              {selectedTableVariant === 'positions' && !isAddingVor && (
                <Tooltip
                  // placement={'left'}
                  // PopperProps={{ disablePortal: true }}
                  title={'Сформировать единичную расценку'}>
                  <div>
                    <Button
                      sx={{
                        padding: '4px 8px !important',
                        height: 'min-content !important',
                        width: 'min-content !important',
                        minWidth: 'min-content !important',
                        maxWidth: '0 !important'
                      }}
                      onClick={() =>
                        openConfirmCreateVor(handleCreateVorFromPositions)
                      }
                      disabled={
                        !selectedWorks || !!filteredExtraWorksOnDelete.length
                      }>
                      <DriveFileMoveOutlinedIcon fontSize={'medium'} />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Divider
            sx={{ height: '4px', backgroundColor: 'rgba(25, 118, 210, 0.08)' }}
          />

          {Content}
          <ModalVOR
            isHandbook={true}
            selectPosition={selectPositions}
            resetFilters={() => {}}
            mainWorks={filteredMainWorksOnDelete}
            extraWorks={filteredExtraWorksOnDelete}
            vorPositions={table.data.elements}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleDelRow={handleDelRow}
            selectedVor={selectedVor}
            isEdit={selectedTableVariant === 'grouped'}
            setSelectedTableVariant={setSelectedTableVariant}
            delArrId={delArrId}
            dispatchVor={dispatchVor}
            setSelectPosition={selectPosition}
          />
          <Parameters
            open={parametersDialogOpen}
            close={() => setParametersDialogOpen(false)}
            isActs={false}
          />
          <ConfirmCreateVor />
        </PositionVorContext.Provider>
      </PageStyled>
    </>
  );
};

export default VorTab;
