import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRowNode } from 'ag-grid-community';
import { calculationsApi } from 'api/calculations';
import { RimRequest } from 'api/calculations/types';
import { RootState } from 'store/store';
import { ComplicatedRimData, ComplicatedRimRow } from '../../../../types';
import { toggleEndFetch, toggleLoader } from './rim.slice';

export const getData = createAsyncThunk<ComplicatedRimData | null, RimRequest>(
  'rim_calculation/getData',
  async (params, { dispatch, getState, signal }) => {
    console.log('getData', params);
    try {
      dispatch(toggleLoader(true));
      const result = await dispatch(
        calculationsApi.endpoints['getCalculationCalcIDComplicated'].initiate({
          ...params
        })
      );
      console.log('server response getData', result);
      if ('data' in result) {
        if (result?.data?.data && result.data?.data.length < params.limit) {
          dispatch(toggleEndFetch(true));
        }
        return result.data ?? null;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      dispatch(toggleLoader(false));
      return null;
    } finally {
      // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
      //   dispatch(appendData({ ...params, page: params.page + 1 }));
      // }

      dispatch(toggleLoader(false));
    }
  }
);
export const appendData = createAsyncThunk<
  ComplicatedRimData | null,
  RimRequest
>('rim_calculation/appendData', async (params, { dispatch, getState }) => {
  console.log('appendData', params);
  const rootState = getState() as RootState;
  try {
    const result = await dispatch(
      calculationsApi.endpoints['getCalculationCalcIDComplicated'].initiate({
        ...params
      })
    );
    console.log('server response appendData', result);
    // grid?.api.applyTransactionAsync({ add: response?.data });
    const limitList = rootState.calculations.rim.calc.limitedRows;
    const gridRef = rootState.AgGrid.gridRef;
    if (!('data' in result)) return null;
    if (result.data?.data && gridRef) {
      const rows: IRowNode<any>[] = [];
      if (limitList.length) {
        for (const el of limitList) {
          const item = gridRef.api.getRowNode(String(el.id));
          if (item) rows.push(item);
        }
        const chapterLimit = gridRef.api.getRowNode(String(-limitList[0].id));
        if (chapterLimit) rows.push(chapterLimit);
      }
      const add = result.data?.limitedCostsAsRows.length
        ? result.data.data.concat(
            [
              {
                id: -result.data?.limitedCostsAsRows[0].id,
                type: 'limit-header' as ComplicatedRimRow['type'],
                title: 'лимитированные',
                hasChildren: false,
                including: {
                  building: null,
                  equipment: null,
                  mounting: null,
                  other: null
                },
                lvl: 1
              }
            ].concat(result.data!.limitedCostsAsRows! as any)
          )
        : result.data.data;
      gridRef.api.applyTransactionAsync({ add, remove: rows }, () => {
        gridRef.api?.setIsExternalFilterPresent(() => true);
        gridRef.api?.onFilterChanged();
      });
      // if (result.data.data.length < params.limit) {
      //   dispatch(toggleEndFetch(true));
      // }
    }
    if (result.data?.data && result.data?.data.length < params.limit) {
      console.log('end fetch');
      console.log('result.data?.data', result.data?.data);
      console.log('params.limit', params.limit);
      dispatch(toggleEndFetch(true));
    }

    return result.data ?? null;
  } catch (e) {
    console.log(e);
    dispatch(toggleLoader(false));
    return null;
  } finally {
    // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
    //   dispatch(appendData({ ...params, page: params.page + 1 }));
    // }
    dispatch(toggleLoader(false));
  }
});
