/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useContext, useMemo } from 'react';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition
} from '../../../../../../assets/SVG/SVG';
import { ExecutionCalculationData } from '../../../../../../types';
import { AgContext } from '../../Accomplishment';
import Cell from '../Cell';
import { Level, PinnedLevel } from './LevelExecution.style';
import { ExecutedTabCellLevelFolder } from '../../../CalculationDirectory/tabs/ExecutedTab';
import { Box, IconButton } from '@mui/material';

interface ILevelExecution
  extends ICellRendererParams<ExecutionCalculationData, any, any> {
  collapse?: any;
  hiddenArr?: any[];
}

const LevelExecution: React.FC<ILevelExecution> = ({ data, context, node }) => {
  const { collapse, hiddenRowsIds } = useContext(AgContext);
  const close = useMemo(() => {
    return hiddenRowsIds?.includes(data?.id!) || false;
  }, [hiddenRowsIds]);

  const onClick = () => {
    collapse?.(data?.id!);
  };

  if (node.rowPinned === 'top') {
    return (
      <PinnedLevel>
        <FunctionsIcon />
        <Cell showTooltip={false}>Суммы</Cell>
      </PinnedLevel>
    );
  }
  const colors = {
    inactive: '#B8B8B8',
    deleted: '#B8B8B8',
    dont_belong: '#FF0101',
    split: '#B78BAC'
  };
  switch (data?.type!) {
    case 'file':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Box
            width={'68px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Level onClick={onClick} arrowClose={close}>
              {data?.children.length ? (
                <IconButton
                  tabIndex={-1}
                  sx={{
                    'svg path': {
                      fill: 'rgba(0, 0, 0, 0.87)'
                    },
                    width: 34,
                    height: 34
                  }}
                  style={{
                    outline: 'none'
                  }}
                  size="small">
                  <KeyboardArrowUpIcon
                    className={'arrow'}
                    tabIndex={-1}
                    sx={{
                      outline: 'none',
                      transition: 'rotate .2s linear forwards'
                    }}
                  />
                </IconButton>
              ) : (
                <Box
                  sx={{
                    padding: '0 17px'
                  }}
                />
              )}
              <ExecutedTabCellLevelFolder>1</ExecutedTabCellLevelFolder>
            </Level>
          </Box>
        </Box>
      );
    case 'level':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Box
            width={'68px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Level onClick={onClick} arrowClose={close}>
              {data?.children.length ? (
                <IconButton
                  tabIndex={-1}
                  sx={{
                    'svg path': {
                      fill: 'rgba(0, 0, 0, 0.87)'
                    },
                    width: 34,
                    height: 34
                  }}
                  style={{
                    outline: 'none'
                  }}
                  size="small">
                  <KeyboardArrowUpIcon
                    className={'arrow'}
                    tabIndex={-1}
                    sx={{
                      outline: 'none',
                      transition: 'rotate .2s linear forwards'
                    }}
                  />
                </IconButton>
              ) : (
                <Box
                  sx={{
                    padding: '0 17px'
                  }}
                />
              )}
              <div>
                <SvgExecutedRate
                  style={{ fill: '#5291DA', width: 17, height: 18 }}
                />
              </div>
            </Level>
          </Box>
        </Box>
      );
    case 'row':
      return (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}>
          <Box
            width={'68px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Level onClick={onClick} arrowClose={close} floatRight>
              <Box
                sx={{
                  padding: '0 17px'
                }}
              />
              <div>
                <SvgExecutedRatePosition
                  style={{
                    width: 17,
                    height: 18,
                    fill: '#B78BAC'
                  }}
                />
              </div>
            </Level>
          </Box>
        </Box>
      );
    default:
      return (
        <Cell>
          {!!data?.children.length && (
            <button onClick={onClick}>collabse</button>
          )}
        </Cell>
      );
  }
};

export default LevelExecution;
