/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React, {
  Context,
  PropsWithChildren,
  useContext,
  useEffect
} from 'react';
type Value = { [key: string]: number };
type ValueOf<T> = (T & Value)[keyof (T & Value)];

type TStepperContext<T extends object> = {
  depth: T;
  maxDepth: T;
  setMaxDepth: (key: keyof T, value: ValueOf<T>) => void;
  toggleDepth: (key: keyof T, value: ValueOf<T>) => void;
};

const StepperContextInstance = React.createContext<any>({} as any);

export const StepperContext: React.FC<
  PropsWithChildren & { defaultValue: Value }
> = (props) => {
  const { children, defaultValue } = props;
  const stepperProps = useStepper<typeof defaultValue>(defaultValue);
  return (
    <StepperContextInstance.Provider value={stepperProps!}>
      {children}
    </StepperContextInstance.Provider>
  );
};
export function useStepperContext<T extends object>(
  fn = () => {
    return;
  },
  keyDepth?: keyof T
) {
  const context = useContext(
    StepperContextInstance as Context<TStepperContext<T>>
  );
  useEffect(fn, keyDepth ? [context?.depth[keyDepth]] : []);
  return context;
}

function useStepper<T extends object>(props: T) {
  const [state, setState] = React.useState<T>(props);

  const [maxDepth, setDepth] = React.useState<T>({} as T);

  const setMaxDepth = React.useCallback((key: keyof T, value: ValueOf<T>) => {
    setDepth((prevState) => ({ ...prevState, [key]: value }));
  }, []);

  const toggleDepth = React.useCallback(
    (key: keyof T, value: ValueOf<T>) => {
      const prev = state[key];
      if ((prev === 1 && value === 1) || value < 1) {
        setState((prevState) => ({
          ...prevState,
          [key]: (prevState[key] as number) - 1
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            [key]: prev
          }));
        });
        return;
      } else if (
        (value === maxDepth[key] && prev === maxDepth[key]) ||
        value > maxDepth[key]
      ) {
        setState((prevState) => ({
          ...prevState,
          [key]: (value as number) + 1
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            [key]: maxDepth[key]
          }));
        });
        return;
      }
      setState((prevState) => ({
        ...prevState,
        [key]: value
      }));
    },
    [maxDepth, state]
  );

  return {
    depth: state,
    maxDepth,
    setMaxDepth,
    toggleDepth
  };
}
