/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const PinnedLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #668fd2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  svg path {
    fill: #668fd2;
  }
`;

export const Level = styled.div<{ floatRight?: boolean; arrowClose: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    margin: 7px;
    transition: rotate linear 0.2s;
  }
  .arrow {
    rotate: 180deg;
    ${({ arrowClose }) => arrowClose && '&:first-child {rotate: 0deg;}'}
  }
`;
