import { IHeaderGroupParams } from '@ag-grid-community/core';
import { CustomHeaderProps } from '@ag-grid-community/react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Typography } from '@mui/material';
import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { ReactComponent as Arrow } from 'assets/icons/rectArrow.svg';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition,
  SvgLimitIcon
} from 'assets/SVG/SVG';
import Cell from 'pages/Calculations/components/Accomplishment/components/Cell';
import { Level } from 'pages/Calculations/components/Accomplishment/components/LevelExecution/LevelExecution.style';
import { ExecutedTabCellLevelFolder } from 'pages/Calculations/components/CalculationDirectory/tabs/ExecutedTab';
import React, { useMemo } from 'react';
import { PartExecutionRim } from 'types';
import {
  ComplicatedDynamicRow,
  IIndexMethodTable
} from 'types/index-method/index-method';
import { replaceStringFromServer } from 'utils/replaceStringFromServer';
import { IIndexMethodCalculationContext } from '.';
import useSearchParam from '../../../hooks/useSearchParams';
import { CustomHeaderWrapper } from './index-method-calculation.styles';
import FunctionsIcon from '@mui/icons-material/Functions';

export const getHeadersDefaultIndexMethodCalculation = (props: {
  totalDynamicRows: ComplicatedDynamicRow[];
}): (ColDef<IIndexMethodTable> | ColGroupDef<IIndexMethodTable>)[] => {
  return [
    {
      suppressMovable: true,
      lockPosition: true,
      field: 'lvl',
      pinned: 'left',
      lockPinned: true,
      resizable: false,
      width: 100,
      minWidth: 100,
      // maxWidth: 100,
      headerName: 'Ур',
      colSpan: (params) => {
        if (params.data?.type === 'limit-header') {
          return params?.columnApi?.getAllColumns()?.length || 1;
        }
        return params.node?.rowPinned === 'top' ? 8 : 0;
      },
      // colSpan: (params) => {
      //   return params.node?.rowPinned === 'top' ? 7 : 0;
      // }
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        isLevel: true
      }
    },
    {
      field: 'number',
      pinned: 'left',
      width: 60,

      // maxWidth: 60,
      minWidth: 60,
      headerName: '№ п\\п',

      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'center',
        isCustomPinned: true
      }
      // colSpan: (params) => {
      //   return params.node?.rowPinned === 'top' ? 7 : 0;
      // }
    },
    {
      field: 'lsr',
      pinned: 'left',
      width: 152,
      headerComponent: 'headerWithFilters',
      // maxWidth: 152,
      minWidth: 152,
      headerName: 'Шифр ЛСР',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'chapter',
      pinned: 'left',
      width: 180,
      headerComponent: 'headerWithFilters',

      // maxWidth: 180,
      minWidth: 180,
      headerName: 'Раздел',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'header',
      pinned: 'left',
      width: 152,
      headerComponent: 'headerWithFilters',

      // maxWidth: 152,
      minWidth: 152,
      headerName: 'Заголовок',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'code',
      pinned: 'left',
      width: 235,
      headerComponent: 'headerWithFilters',

      // maxWidth: 235,
      minWidth: 235,
      headerName: 'Код расценки',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'title',
      pinned: 'left',
      width: 400,
      headerComponent: 'headerWithFilters',
      // maxWidth: 400,
      minWidth: 400,
      headerName: 'Наименование',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },

    {
      field: 'unit',
      width: 152,
      headerComponent: 'headerWithFilters',
      // maxWidth: 152,
      minWidth: 152,
      headerName: 'Ед. изм.',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'center'
      }
    },
    {
      headerName: 'Кол-во',
      lockPinned: true,
      marryChildren: true,
      children: [
        {
          field: 'quantity.perUnit',
          width: 148,
          lockPinned: true,
          // maxWidth: 148,
          minWidth: 148,
          headerName: 'на единицу измерения',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'quantity.coefficient',
          width: 148,
          lockPinned: true,
          // maxWidth: 148,
          minWidth: 148,
          headerName: 'коэффициенты',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'quantity.total',
          width: 148,
          lockPinned: true,
          // maxWidth: 148,
          minWidth: 148,
          headerName: 'всего с учетом коэффициентов',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        }
      ]
    },
    {
      headerName: 'Сметная стоимость, руб.',
      headerGroupComponent: 'headerComponent',
      suppressMovable: true,
      marryChildren: true,
      lockPinned: true,
      children: [
        {
          field: 'price.basis.perUnit',
          width: 158,
          lockPinned: true,
          // maxWidth: 158,
          minWidth: 158,
          headerName: 'на единицу измерения в базисном уровне цен',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'price.basis.multiplier',
          width: 80,
          lockPinned: true,
          // maxWidth: 80,
          minWidth: 80,
          headerName: 'индекс',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'price.current.perUnit',
          width: 158,
          lockPinned: true,
          // maxWidth: 158,
          minWidth: 158,
          headerName: 'на единицу измерения в текущем уровне цен',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'price.current.multiplier',
          width: 136,
          lockPinned: true,
          // maxWidth: 124,
          minWidth: 136,
          headerName: 'коэффициенты',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        // ...(
        //   ? [
        {
          field: 'price.total' as keyof IIndexMethodTable,
          width: 158,
          lockPinned: true,
          headerComponent: 'customHeader',
          headerComponentParams: {
            header: props.totalDynamicRows.length ? 'итого' : 'всего'
          },
          // maxWidth: 158,
          minWidth: 158,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        }
        //       ]
        //     : [
        //         {
        //           field: 'price.total' as keyof IIndexMethodTable,
        //           width: 158,
        // //           maxWidth: 158,
        //           minWidth: 158,
        //           headerComponent: 'customHeader',
        //           headerName: 'всего в текущем уровне цен',
        //           cellRenderer: 'cellRenderer',
        //           cellRendererParams: {
        //             float: 'right',
        //             decimalMax: 6
        //           }
        //         }
        //       ])
      ]
    },
    {
      headerName: 'В том числе',
      headerClass: 'header-group',
      marryChildren: true,
      lockPinned: true,
      children: [
        {
          field: 'including.building',
          width: 158,
          hide: true,
          lockPinned: true,

          // maxWidth: 158,
          minWidth: 158,
          headerName: 'Строительные работы',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'including.mounting',
          width: 158,
          hide: true,
          lockPinned: true,
          // maxWidth: 158,
          minWidth: 158,
          headerName: 'Монтажные работы',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'including.equipment',
          width: 158,
          hide: true,
          lockPinned: true,
          // maxWidth: 158,
          minWidth: 158,
          headerName: 'Оборудование, мебель, инвентарь',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        },
        {
          field: 'including.other',
          width: 158,
          hide: true,
          lockPinned: true,
          // maxWidth: 158,
          minWidth: 158,
          headerName: 'Прочие',
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        }
      ]
    },
    ...(props.totalDynamicRows
      ? props.totalDynamicRows.map((row, idx) => ({
          field: `dynamicRows.${idx}.value` as keyof IIndexMethodTable,
          width: 158,
          // maxWidth: 158,
          headerComponent: 'customHeaderMultiline',
          minWidth: 158,
          headerName: `${row.title ?? ''}`,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'right',
            decimalMax: 6
          }
        }))
      : []),
    ...(props.totalDynamicRows.length
      ? [
          {
            field: 'total' as keyof IIndexMethodTable,
            width: 136,
            // maxWidth: 120,
            minWidth: 136,
            headerName: 'Всего стоимость',
            cellRenderer: 'cellRenderer',
            cellRendererParams: {
              float: 'right',
              decimalMax: 6
            }
          }
        ]
      : []),
    {
      field: 'tz',
      width: 136,

      // maxWidth: 120,
      minWidth: 136,
      headerName: 'ТЗ',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'right',
        decimalMax: 6
      }
    },
    {
      field: 'tzm',
      width: 136,

      // maxWidth: 120,
      minWidth: 136,
      headerName: 'ТЗМ',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'right',
        decimalMax: 6
      }
    }
  ];
};

export const CellRendererIndexMethod = (
  props: ICellRendererParams<
    IIndexMethodTable,
    unknown,
    IIndexMethodCalculationContext
  >
) => {
  const isPinnedTop = props.node.rowPinned === 'top';
  const decimalMax = props.colDef?.cellRendererParams?.decimalMax;
  const decimalMaxTooltip = 18;
  const isBold = props?.data?.type === 'total';

  const [errors, setErrors] = React.useState<{ [key: number]: boolean }>({});

  React.useEffect(() => {
    if (
      props.data &&
      'parts' in props.data &&
      props.data?.type !== 'file' &&
      props.data?.type !== 'total' &&
      props?.colDef?.cellRendererParams?.idx
    ) {
      const result: { [key: string]: boolean } = {};
      let total = 0;
      const temp = (props.data.parts as PartExecutionRim[]).reduce(
        (acc, act, index) => {
          if (!acc[index]) {
            acc[index] = {
              value: 0,
              type: '',
              total: 0
            };
            acc[index].type = act.type!;
            if (acc[index].type === 'total') {
              acc[index].value = 0;
              acc[index].total = act.quantity?.total ?? 0;
              total = acc[index].total;
            } else if (acc[index].type === 'act') {
              if (acc[index - 1]) {
                acc[index].value =
                  Math.round(
                    (acc[index - 1].value + (act.quantity?.total ?? 0)) *
                      Math.pow(10, 15)
                  ) / Math.pow(10, 15);
                acc[index].total = total;
              }
            } else {
              acc[index].value = act.quantity.total ?? 0;
              acc[index].total = total;
            }
          }

          return acc;
        },
        {} as { [key: string]: { value: number; type: string; total: number } }
      );

      for (const index in temp) {
        const tempKey = Number(index);
        const currentPart = temp[tempKey];

        if (currentPart.type !== 'total') {
          if (currentPart.type !== 'rest') {
            if (currentPart.total > 0) {
              result[tempKey] = currentPart.value > currentPart.total;
            } else {
              result[tempKey] = currentPart.value < currentPart.total;
            }
          } else {
            if (currentPart.total > 0) {
              result[tempKey] = currentPart.value < 0;
            } else {
              result[tempKey] = currentPart.value > 0;
            }
          }
        }
        setErrors(result);
      }
    }
    // setErrors(checkError((props.data as any).parts));
  }, [(props.data as any)?.parts]);

  if (isPinnedTop && props.colDef?.cellRendererParams?.isPinnedTopHidden) {
    return <></>;
  }

  // if (isPinnedTop && props.colDef?.cellRendererParams?.isCustomPinned) {
  //   return (
  //
  //   );
  // }

  if (isPinnedTop && props.colDef?.cellRendererParams?.isLevel) {
    return (
      <Box
        className="hiddenRightBorder"
        display="flex"
        width={'100%'}
        justifyContent={'flex-start'}
        alignItems={'center'}>
        <FunctionsIcon />
        <Box display="flex" justifyContent={'flex-start'} alignItems={'center'}>
          <Typography variant="h1" color="primary.light" fontSize={'14px'}>
            Суммы
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isPinnedTop) {
    return (
      <Cell
        style={{ color: '#2B3648' }}
        float={props.colDef?.cellRendererParams?.float ?? 'right'}
        originData={
          props.value
            ? decimalMax
              ? replaceStringFromServer(String(props.value), decimalMaxTooltip)
              : String(props.value)
            : ''
        }>
        {props.value
          ? decimalMax
            ? replaceStringFromServer(String(props.value), 2)
            : String(props.value)
          : ''}
      </Cell>
    );
  }

  if (
    props.colDef?.cellRendererParams?.isFolderHidden &&
    props.data?.type === 'file'
  ) {
    return <></>;
  }

  if (props.colDef?.cellRendererParams?.isLevel) {
    const hasChildren = props.data?.hasChildren;
    const type = props.data?.type;
    const close = !!props.context?.collapseRowsIds?.has(props.data?.id!);
    // const close = !!(props.context?.collapseRowsIds ?? []).find(
    //   (e) => e === props.data?.id
    // );
    const onClick = () => {
      if (props.context.onCollapse && props.data?.id) {
        props.context.onCollapse([props.data?.id as number]);
      }
    };

    switch (type) {
      case 'limit':
        return (
          <Level
            arrowClose={true}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'default'
            }}>
            <Box
              sx={{
                padding: '0 17px'
              }}
            />
            <div>
              <SvgLimitIcon />
            </div>
          </Level>
        );
      case 'limit-header':
        return <Cell color="#5C6E8C">Лимитированные затраты</Cell>;
      case 'file':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level onClick={onClick} arrowClose={!close}>
                {hasChildren ? (
                  <IconButton
                    tabIndex={-1}
                    sx={{
                      'svg path': {
                        fill: 'rgba(0, 0, 0, 0.87)'
                      },
                      width: 34,
                      height: 34
                    }}
                    style={{
                      outline: 'none'
                    }}
                    size="small">
                    <KeyboardArrowUpIcon
                      className={'arrow'}
                      tabIndex={-1}
                      sx={{
                        outline: 'none',
                        transition: 'rotate .2s linear forwards'
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box
                    sx={{
                      padding: '0 17px'
                    }}
                  />
                )}
                <div>
                  <ExecutedTabCellLevelFolder>
                    {props.value ? String(props.value) : ''}
                  </ExecutedTabCellLevelFolder>
                </div>
              </Level>
            </Box>
          </Box>
        );
      case 'level':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level onClick={onClick} arrowClose={!close}>
                {hasChildren ? (
                  <IconButton
                    tabIndex={-1}
                    sx={{
                      'svg path': {
                        fill: 'rgba(0, 0, 0, 0.87)'
                      },
                      width: 34,
                      height: 34
                    }}
                    style={{
                      outline: 'none'
                    }}
                    size="small">
                    <KeyboardArrowUpIcon
                      className={'arrow'}
                      tabIndex={-1}
                      sx={{
                        outline: 'none',
                        transition: 'rotate .2s linear forwards'
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box
                    sx={{
                      padding: '0 17px'
                    }}
                  />
                )}
                <div>
                  <SvgExecutedRate style={{ fill: '#5291DA' }} />
                </div>
              </Level>
            </Box>
          </Box>
        );
      case 'row':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level onClick={onClick} arrowClose={!close}>
                <Box
                  sx={{
                    padding: '0 17px'
                  }}
                />
                <div>
                  <SvgExecutedRatePosition
                    style={{
                      fill: '#B78BAC'
                    }}
                  />
                </div>
              </Level>
            </Box>
          </Box>
        );
      default:
        return (
          <Cell style={{ color: '#2B3648' }}>
            {hasChildren && <button onClick={onClick}>collabse</button>}
          </Cell>
        );
    }
  }

  return (
    <Cell
      style={{
        color: errors?.[props?.colDef?.cellRendererParams?.idx]
          ? 'red'
          : '#2B3648',
        fontWeight: isBold ? '600' : ''
      }}
      float={props.colDef?.cellRendererParams?.float ?? 'left'}
      // isError={!!errors?.[props?.colDef?.cellRendererParams?.idx]}
      // isError={
      //   props.data && 'parts' in props.data
      //     ? isHaveColorRow(
      //         props.data?.type,
      //         props?.colDef?.cellRendererParams?.idx || undefined,
      //         errors
      //       )
      //     : false
      // }
      originData={
        props.value
          ? decimalMax
            ? replaceStringFromServer(String(props.value), decimalMaxTooltip)
            : String(props.value)
          : String(props.value)
      }>
      {props.value
        ? decimalMax
          ? replaceStringFromServer(String(props.value), +decimalMax)
          : String(props.value)
        : ''}
    </Cell>
  );
};
export const headerRendererIndexMethod = (props: IHeaderGroupParams) => {
  const [expandState, setExpandState] = React.useState<
    'expanded' | 'collapsed'
  >('collapsed');
  const expandOrCollapse = React.useCallback(() => {
    const currentState = props.columnGroup
      .getProvidedColumnGroup()
      .isExpanded();
    const targetColumns = [
      'including.building',
      'including.mounting',
      'including.equipment',
      'including.other'
    ];

    // Получаем текущую видимость и переключаем её
    targetColumns.forEach((colId) => {
      const isVisible =
        (props as any).columnApi
          .getColumnState()
          .find((col: any) => col.colId === colId)?.hide !== true;
      (props as any).columnApi.setColumnVisible(colId, !isVisible);
    });
    props.setExpanded(!currentState);
  }, [props]);

  const syncExpandButtons = React.useCallback(() => {
    setExpandState(
      props.columnGroup.getProvidedColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    );
  }, []);
  React.useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();
    // return () => {
    //   props.columnGroup.getProvidedColumnGroup().removeEventListener('expandedChanged', syncExpandButtons);
    // };
  }, []);
  return (
    <CustomHeaderWrapper isOpen={expandState === 'expanded'}>
      {props.displayName}
      <IconButton
        style={{
          position: 'absolute',
          right: '0',
          top: '4px'
        }}
        onClick={expandOrCollapse}>
        <Arrow />
      </IconButton>
    </CustomHeaderWrapper>
  );
};
export const customHeader = (props: CustomHeaderProps) => {
  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      {props.column.getUserProvidedColDef()?.headerComponentParams.header}
      <br />в текущем уровне цен
    </div>
  );
};
export const customHeaderMultiline = (props: CustomHeaderProps) => {
  return (
    <div
      style={{
        textAlign: 'center',
        margin: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical'
      }}>
      {props.displayName}
    </div>
  );
};

interface MyCustomHeaderProps {
  menuIcon: string;
  displayName: string;
  column: {
    colDef: {
      field: string;
    };
  };
  context: any;
}

// const filterView: Record<Exclude<TChips, null>, keyof CommonFiltersRequest> = {
//   title: 'nameIDs',
//   number: 'lsrNumIDs',
//   header: 'titleIDs',
//   code: 'priceCodeIDs',
//   lsr: 'lsrCodeIDs',
//   chapter: 'chapterIDs'
// };
export const HeaderComponentWithFilter: React.FC<MyCustomHeaderProps> = (
  props
) => {
  const { toggleDrawer, bodyParams } = props.context;
  const [qs, setQs] = useSearchParam({
    key: props.column.colDef.field
  });
  console.log('log HeaderComponentWithFilter', qs);
  const onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.column && props.column.colDef && props.column.colDef.field) {
      toggleDrawer?.(props.column.colDef.field);
      // if (props.column.colDef.field === 'kfoName') {
      //   props?.context?.changeDialog?.('groups' as any) || changeDialog?.('groups' as any);
      // } else {
      //   props?.context?.changeDialog?.((props.column.colDef.field + 's') as any) ||
      //   changeDialog((props.column.colDef.field + 's') as any);
      // }
    }
  };
  const isActive = useMemo(() => {
    // return false;
    return !!bodyParams?.[props.column.colDef.field]?.length;
    // return (
    //   !!checkedData?.[
    //     filterView[props.column.colDef.field as keyof typeof filterView]
    //   ]?.length || false
    // );
  }, [bodyParams, props.column.colDef.field]);
  return (
    <Box
      position={'relative'}
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}>
      {props.displayName}

      <Box position={'absolute'} right={5}>
        <IconButton tabIndex={-1} onClick={onClick}>
          <FilterAltIcon
            fontSize={'small'}
            sx={{ fill: isActive ? '#0044B4' : '#9AA2B0' }}
            // sx={{ fill: '#9AA2B0' }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
