import { createAsyncThunk } from '@reduxjs/toolkit';
import { calculationsApi } from 'api/calculations';
import {
  GetComplicatedListResponse,
  GetRowsListV2Request
} from 'api/calculations/types';
import { RootState } from 'store/store';
import { toggleEndFetch, toggleLoader } from './bim.slice';

export const getData = createAsyncThunk<
  GetComplicatedListResponse | null,
  GetRowsListV2Request
>('bim_calculation/getData', async (params, { dispatch, getState }) => {
  console.log('getData', params);
  try {
    dispatch(toggleLoader(true));
    const result = await dispatch(
      calculationsApi.endpoints['getComplicatedRowsListV2'].initiate({
        ...params
      })
    );
    if (result.data?.data && result.data?.data.length < params.limit) {
      dispatch(toggleEndFetch(true));
    }
    console.log('server response getData', result);
    return result.data ?? null;
  } catch (e) {
    console.log(e);
    dispatch(toggleLoader(false));
    return null;
  } finally {
    // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
    //   dispatch(appendData({ ...params, page: params.page + 1 }));
    // }
    dispatch(toggleLoader(false));
  }
});
export const appendData = createAsyncThunk<
  GetComplicatedListResponse | null,
  GetRowsListV2Request
>('bim_calculation/appendData', async (params, { dispatch, getState }) => {
  console.log('appendData', params);

  try {
    const result = await dispatch(
      calculationsApi.endpoints['getComplicatedRowsListV2'].initiate({
        ...params
      })
    );
    console.log('server response appendData', result);
    // grid?.api.applyTransactionAsync({ add: response?.data });
    const gridRef = (getState() as RootState).AgGrid.gridRef;
    if (result.data?.data && gridRef) {
      gridRef.api.applyTransactionAsync({ add: result.data.data }, () => {
        gridRef.api?.setIsExternalFilterPresent(() => true);
        gridRef.api?.onFilterChanged();
      });
      if (result.data.data.length < params.limit) {
        dispatch(toggleEndFetch(true));
      }
    }
    if (result.data?.data && result.data?.data.length < params.limit) {
      dispatch(toggleEndFetch(true));
    }

    return result.data ?? null;
  } catch (e) {
    console.log(e);
    dispatch(toggleLoader(false));
    return null;
  } finally {
    // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
    //   dispatch(appendData({ ...params, page: params.page + 1 }));
    // }
    dispatch(toggleLoader(false));
  }
});
